import React, { useState, useEffect } from "react";
import styled from "styled-components";

import radio_check from '../../../assets/images/radio_check.svg';
import search_lg from '../../../assets/images/search-lg.png';
import SaveToast from "../../../components/toast/save";

import 'react-tagsinput/react-tagsinput.css';
import { useDispatch, useSelector } from "react-redux";
import { getFitnessCriteriaListData, getIncentiveList, getInterviewScriptDesign, postInterviewScriptDesign, updateInterviewScriptDesign } from "../../../action/api";
import { getErrorMessage } from "../../../config/common";

export default function Design(props) {
    const { flag, item, design, flagFunc } = props;
    const state = useSelector((state) => state.apiReducer);
    const stateFitness = useSelector((state) => state.apiFitnessReducer);
    const stateInterviewScripts = useSelector((state) => state.apiInteriewScriptsReducer);
    const [toast, setToast] = useState(-1);
    const [incentive_list, set_incentive_list] = useState([]);
    const [cta_contextual_text, set_cta_contextual_text] = useState(design[0] ? design[0].cta_contextual_text : '');
    const [cta_secondary_template, set_cta_secondary_template] = useState(design[0] ? design[0].cta_secondary_template : '');
    const [cta_secondary_text, set_cta_secondary_text] = useState(design[0] ? design[0].cta_secondary_text : '');
    const [cta_standard_text, set_cta_standard_text] = useState(design[0] ? design[0].cta_standard_text : '');
    const [experience, set_experience] = useState(design[0] ? design[0].experience : '');
    const [sec_cta_template, set_sec_cta_template] = useState();
    const [fitness_criteria_id, set_fitness_criteria_id] = useState(design[0] ? design[0].fitness_criteria_id : '');
    const [incentive_id, set_incentive_id] = useState(design[0] ? design[0].incentives_id : '');
    const [interview_script_completed_text, set_interview_script_completed_text] = useState(design[0] ? design[0].interview_script_completed_text : '');
    const [interview_script_incentive_text, set_interview_script_incentive_text] = useState(design[0] ? design[0].interview_script_incentive_text : '');
    const [style, set_style] = useState(design[0] ? design[0].style : '');
    const [nDesign, setNDesign] = useState({});
    const [selectedOption, setSelectedOption] = useState('flexRadioDefault1');
    const [errorMsg, setErrorMsg] = useState("");

    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const dispatch = useDispatch();
    useEffect(() => {
      getInterviewScriptDesign(item.id)(dispatch);
      getFitnessCriteriaListData()(dispatch);
      getIncentiveList()(dispatch).then((res) => {
        if (res && res.objects)
          set_incentive_list([...res.objects]);
      });
    }, [dispatch]);
    useEffect(() => {
        if (toast) {
          setTimeout(() => {
            setToast(-1)
          }, 5000);
        }
      }, [toast])
   useEffect(() => {
    if(flag == 1) {
        if ((design && design.length > 0) || nDesign.call_to_action_id) {
            const tempObj = design[0] || nDesign;
            updateInterviewScriptDesign(item.id, tempObj.id, {
            "call_to_action_id": tempObj.call_to_action_id,
            "cta_contextual_text": cta_contextual_text,
            "cta_secondary_template": cta_secondary_template,
            "cta_secondary_text": cta_secondary_text,
            "cta_standard_text": cta_standard_text,
            "experience": experience,
            "fitness_criteria_id": fitness_criteria_id,
            "incentive_id": tempObj.incentive_id || null,
            "incentive_id": incentive_id || null,
            "interview_script_completed_text": interview_script_completed_text,
            "interview_script_incentive_text": interview_script_incentive_text,
            "style": style
          })(dispatch).then(res => {
            if (res.error !== undefined) {
                setErrorMsg(getErrorMessage(res.fields));
                setToast(2);
            }
            else {
                getDesign();
                setToast(1);
            }
          })
        } else {
            postInterviewScriptDesign(item.id , {
                "cta_contextual_text": cta_contextual_text,
                "cta_secondary_template": cta_secondary_template,
                "cta_secondary_text": cta_secondary_text,
                "cta_standard_text": cta_standard_text,
                "experience": experience,
                "fitness_criteria_id": fitness_criteria_id,
                "incentive_id": incentive_id || null,
                "interview_script_completed_text": interview_script_completed_text,
                "interview_script_incentive_text": interview_script_incentive_text,
                "style": style,
                "account_id": localStorage.getItem('account_id')
              })(dispatch).then(res => {
                if (res.error !== undefined) {
                    setErrorMsg(getErrorMessage(res.fields.text));
                    setToast(2);
                }
                else {
                    getDesign();
                    setToast(1);
                }
              })
        }
        flagFunc();
    }
   },[flag])
   function getDesign() {
        getInterviewScriptDesign(item.id)(dispatch).then(res => {
            if (res && res.objects && res.objects[0]) {
                set_cta_contextual_text(res.objects[0].cta_contextual_text || '');
                set_cta_secondary_template(res.objects[0].cta_secondary_template || '');
                set_cta_secondary_text(res.objects[0].cta_secondary_text || '');
                set_cta_standard_text(res.objects[0].cta_standard_text || '');
                set_experience(res.objects[0].experience || '');
                set_fitness_criteria_id(res.objects[0].fitness_criteria_id || '');
                set_incentive_id(res.objects[0].incentives_id || '');
                set_interview_script_completed_text(res.objects[0].interview_script_completed_text || '');
                set_interview_script_incentive_text(res.objects[0].interview_script_incentive_text || '');
                set_style(res.objects[0].style || '');
                setNDesign({...res.objects[0]})
            }
      })
   }
  return (<DesignWrapper>
    <InterviewScriptHeader>
        <h2>Design</h2>
    </InterviewScriptHeader>
    <InterviewScriptBody>
        <div className="script-section">
            <h3>Fitness Criteria</h3>
            <div className="form-group">
                <label htmlFor={"fitness-crierial"}>Fitness criteria list set for this interview</label>
                <select className="form-control form-select" id="fitness_criteria_id" aria-describedby="fitness-crierial" value={fitness_criteria_id} onChange={(e) => { set_fitness_criteria_id(e.target.value)}}>
                    <option value="">Choose fitness crieria list</option>
                    {stateFitness && stateFitness.fitness_critera_list.map((fitness, index) => (
                        <option value={fitness.id}>{fitness.name}</option>
                    ))}
                </select>
            </div>
        </div>
        <div className="script-section">
            <h3>Interview experience & style</h3>
            <div className="form-group">
                <label htmlFor={"fitness-crierial"}>Choose interview experience</label>
                <div className="select-group">
                    <select className="form-control form-select" id="fitness-experience" aria-describedby="fitness-experience" value={experience} onChange={(e) => { set_experience(e.target.value)}}>
                        <option value="">Choose experience</option>
                        <option value="legacy">Legacy</option>
                        <option value="v2">V2</option>
                        <option value="v3">V3</option>
                    </select>
                    <p>in a </p>
                    <select className="form-control form-select" id="fitness-style" aria-describedby="fitness-style" value={style} onChange={(e) => { set_style(e.target.value)}}>
                        <option value="">Choose style</option>
                        <option value="popup">Popup</option>
                        <option value="full">Full</option>
                        <option value="landing">Landing</option>
                    </select>
                </div>
            </div>
        </div>
        <div className="script-section">
            <h3>Call to Action</h3>
            <div className="form-group">
                <label htmlFor={"fitness-crierial"}>Choose the type of primary CTA that you would like to use</label>
                <div className="form-check">
                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"  value="flexRadioDefault1" checked={selectedOption === "flexRadioDefault1"} onChange={handleRadioChange}/>
                        Standard call to action
                    </label>
                    <input type="text" className="form-control" id="stand_call" aria-describedby="stand_call" placeholder="Help us better tailor your experience!" value={cta_standard_text} onChange={(e) => { set_cta_standard_text(e.target.value)}}/>
                </div>
                <div className="form-check">
                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"  value="flexRadioDefault2" checked={selectedOption === "flexRadioDefault2"} onChange={handleRadioChange}/>
                        Contextual call to action
                    </label>
                    <input type="text" className="form-control" id="contextual_call" aria-describedby="contextual_call" placeholder="I see you're interested in <nameOfWebinar>. Help us better tailor your experience!" value={cta_contextual_text} onChange={(e) => { set_cta_contextual_text(e.target.value)}} />
                </div>
                <div className="form-check">
                    <label className="form-check-label" htmlFor="flexRadioDefault3">
                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" value="flexRadioDefault3" checked={selectedOption === "flexRadioDefault3"} onChange={handleRadioChange}/>
                        Panda AI generated call to action
                    </label>
                </div>
                <h4>Secandary Call to Action</h4>
                <div className="secondary d-flex">  
                    <div className="form-group">
                        {/* <input type="text" className="form-control" id="cta_template" aria-describedby="cta_template" placeholder="Choose template" value={cta_secondary_template} onChange={(e) => { set_cta_secondary_template(e.target.value)}}/> */}
                        <label htmlFor={"cta_template"}>Secondary CTA template (optional)</label>
                        <div className="select-group">
                            <select className="form-control form-select" id="secondary-cta-tempplate" aria-describedby="secondary-cta-template" value={sec_cta_template} onChange={(e) => { set_sec_cta_template(e.target.value)}}>
                                <option value="">Choose template</option>
                                <option value="sec-cta-temp1">cta template1</option>
                                <option value="sec-cta-temp2">cta template2</option>
                                <option value="sec-cta-temp3">cta template3</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor={"cta_text"}>Secondary CTA text</label>
                        <input type="text" className="form-control" id="cta_text" aria-describedby="cta_text" placeholder="Jump on it right now!" value={cta_secondary_text} onChange={(e) => { set_cta_secondary_text(e.target.value)}}/>
                    </div>
                </div>
            </div>
        </div>
        <div className="script-section">
            <h3>Interview Incentives</h3>
            <div className="form-group">
                <label htmlFor={"fitness-crierial"}>Offer an incentive to complete the interview</label>
                <div className="select-group">
                    <select className="form-control form-select" id="fitness-experience" aria-describedby="fitness-experience" value={incentive_id} onChange={(e) => { 
                        set_incentive_id(e.target.value);
                        var tempList = incentive_list.filter(fit => fit.id == e.target.value);
                        if (tempList && tempList.length > 0 )
                            set_interview_script_incentive_text(tempList[0].description)
                        else {
                            set_interview_script_incentive_text('')
                        }
                    }}>
                        <option value="">Choose incentive</option>
                        {incentive_list && incentive_list.map((fitness, index) => (
                            <option value={fitness.id}>{fitness.name}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="form-group">
                <label htmlFor={"fitness-crierial"}>Incentive description</label>
                <input type="text" className="form-control" id="incentive_description" aria-describedby="incentive_description" placeholder="Answer a few quick questions for receive a free coffee e-gift card from Starbucks or Peet’s!" value={interview_script_incentive_text} onChange={(e) => { set_interview_script_incentive_text(e.target.value)}} />
            </div>
        </div>
        <div className="script-section">
            <h3>Interview Completed Message</h3>
            <div className="form-group">
                <label htmlFor={"interview_completed"}>Messaging on the interview exit screen</label>
                <input type="text" className="form-control" id="interview_completed" aria-describedby="interview_completed" placeholder="Thank you so much for sharing this information with us! Your responses will help us better serve your needs" value={interview_script_completed_text} onChange={(e) => { set_interview_script_completed_text(e.target.value)}} />
            </div>
        </div>
    </InterviewScriptBody>
    {toast != -1 && (<SaveToast closeToast={()=>{setToast(-1)}} toast={toast} error={errorMsg} />)}
  </DesignWrapper>)
}

const DesignWrapper = styled.div`
    position: relative;
    padding: 0px;
    width: 100%;
`
const InterviewScriptHeader = styled.div`
    padding: 20px 24px;
    width: 100%;
    h2 {
        color: #101828;
        font-family: Figtree;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 155.556% */
        margin-bottom: 0px;
    }
    border-bottom: 1px solid #EAECF0;
`
const InterviewScriptBody= styled.div`
    padding: 20px 24px;
    .script-section {
        h3 {
            color: #101828;
            font-family: Figtree;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
        }
        .form-group label {
            font-family: Figtree;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: #344054;
        }
        .select-group {
            display: flex;
            p {
                margin-left: 10px;
                margin-right: 5px;
                min-width: 35px;
                margin-bottom: 0px;
                line-height: 38px;
            }
        }
        select.form-control.form-select {
            border-radius: 8px;
            border: 1px solid var(--gray-300, #D0D5DD);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            color: var(--gray-500, #667085);
            font-family: Figtree;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 125% */
        }
        input[type=text].form-control {
            border-radius: 8px;
            border: 1px solid var(--gray-300, #D0D5DD);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            color: var(--gray-500, #667085);
            font-family: Figtree;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
        }
        .form-check label {
            margin-bottom: 6px;
        }
        h4 {
            color: #101828;
            font-family: Figtree;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 142.857% */
            margin-top: 20px;
            margin-bottom: 12px;
        }
        .secondary.d-flex .form-group{
            margin-right: 20px;
        }
        // border-bottom: 1px solid #EAECF0;
        margin-bottom: 20px;
    }
    .form-incentive {
        input.form-check-input {
            margin-top: 18px;
        }
        .form-check-label {
            display: flex;
            margin-bottom: 20px;
            .incentive-content{
                width: 100%;
                input {
                    border-radius: 8px;
                    border: 1px solid #D0D5DD);
                    background: #FFF;
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                    margin-top: 6px;
                }
            }
            .upload-img {
                h4 {
                    margin: 2px;
                    color: #475467;
                    font-family: Figtree;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px; /* 142.857% */
                    text-align: center;
                    margin-right: 16px;
                }
            }
            img {
                width: 52px;
                height: 52px;
                margin-right: 16px;
                margin-left: 8px;
            }
            h2 {
                color: #101828;
                font-family: Figtree;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px; /* 150% */
                margin-bottom: 0px;
            }
            p {
                color: #475467;
                font-family: Figtree;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 150% */     
                margin-bottom: 0px;
            }
        }
    }
    .form-check-input:checked[type=radio] {
        border: 1px solid var(--primary-600, #1693C7);
        background: var(--primary-50, #E7F7FF);
        background-image: url(${radio_check});
        background-repeat: no-repeat;
        background-position: center;
        background-size: 6px 6 px;
    }
    .form-check-input:checked {
        background-color: #1693C7;
        border-color: #1693C7;
    }
    .script-section {
        .form-control-focus {
            color: #212529;
            background-color: #fff;
            border-color: #86b7fe;
            outline: 0;
            box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
          }
          
          .was-validated :valid + .form-control-focus {
            border-color: #198754;
            box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
          }
          
          .was-validated :invalid + .form-control-focus {
            border-color: #dc3545;
            box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
          }
    }
    .company-location {
        width: 400px;
        margin-top: 12px;
        margin-bottom: 12px;
        .react-tagsinput {
            border-radius: 8px;
            border: 1px solid var(--gray-300, #D0D5DD);
            background: var(--base-white, #FFF);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            background-image: url(${search_lg});
            background-repeat: no-repeat;
            background-position-x: 9px;
            background-position-y: 11px;
            padding-left: 35px;
        }
        .react-tagsinput-tag {
            border-radius: 6px;
            border: 1px solid var(--gray-300, #D0D5DD);
            background: var(--base-white, #FFF);
            color: var(--gray-700, #344054);
            font-family: Figtree;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
            padding:2px 8px;
        }
    }
`
