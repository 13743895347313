import React, { useState, useEffect } from "react"
import styled from "styled-components"
import pen1 from './../assets/images/pen1.png';
import square1 from './../assets/images/sqaure1.png';

import { useDispatch, useSelector } from "react-redux";
import {
  receiveAccountsData,
  addAccountData,
  updateAccountData
} from "./../action/api";

import Sidebar from "./../components/sidebar";
import Header from "./../components/header";
import Button from "../components/basics/button";
import Badge from "../components/basics/badge";
import Pagination from "../components/basics/pagination";
import AccountModal from "../components/modals/account";
import SaveToast from "../components/toast/save";

export default function Accounts(props) {
  const state = useSelector((state) => state.apiReducer);
  const [currentPage, setCurrentPage] = useState(state.account_page || 1);
  const [totalPage, setTotalPage] = useState(state.account_total);
  const [toast, setToast] = useState(-1);
  const dispatch = useDispatch();
  useEffect(() => {
    getAccounts(state.account_page, state.account_limit);
  }, [dispatch]);
  const getAccounts = (a_page, a_limit) => {
    receiveAccountsData(a_page, a_limit)(dispatch).then(res=> {
      if (res && res.objects) setAccounts([...res.objects]);
      if (res && res.total) setTotalPage(res.total);
    });
  }
  const [search, setSearch] = useState('');
  const [showModal, setShowModal] = useState(-2);
  const closeModal = () => {
      setShowModal(-2);
  };
  const [accounts, setAccounts] = useState(state.accounts || []);
  const linkToDashboard = () => {

  }
  const editAccount = (index) => {
    setShowModal(index);
  }
  useEffect(() => {
    if (toast) {
      setTimeout(() => {
        setToast(-1)
      }, 5000);
    }
  }, [toast]);
  // const {token, setToken} = useToken();
  // if (!token) {
  //     return <Login/>
  // }
  return (<AccountsWrapper className='container-fluid'>
    <div className="row flex-nowrap">
      <div className="col-auto px-0 bg-sidebar">
        <Sidebar page="accounts"/>
      </div>
      <div className="col body p-0">
        <Header title={'Accounts'}  onPrevMain={()=>{
          setShowModal(-1); 
        }}  />
        <BodyWrapper>
          <div className="filter-wrapper">
            <Button title="Filters" icon="filter" outline/>
            <input type="text" value={search}  onChange={(e)=>{setSearch(e.target.value);}}placeholder='Search'/>
          </div>
          <div className="body-card">
            <table>
              <thead>
                <tr>
                  <th scope="col">Company</th>
                  <th scope="col">Key Contacts</th>
                  <th scope="col">Created</th>
                  <th scope="col">Interviews in last 24hrs</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody> 
                {accounts &&  accounts.length > 0  && accounts.map((account,index) => (
                  <tr key={'account-' + index}>
                    <td className="account-company">
                      <h3>{account.name}</h3>
                      <p>{account.website}</p>
                    </td>
                    <td className="account-badges">
                      {account.key_contacts && account.key_contacts.map((keyContract, subIndex) => (
                        <Badge title={keyContract} key={"badge-" + subIndex} />
                      ))}
                    </td>
                    <td>{account.created_date}</td>
                    <td>{account.num_interviews}</td>
                    <td className="account-action">
                      <img src={square1} alt="square" onClick={()=> {
                          window.open(account.website, '_blank').focus();
                        }}/>
                      <img src={pen1} alt="pen" onClick={()=> {editAccount(index);}}/> 
                    </td>
                  </tr>
                ))}
                {(!accounts || accounts.length == 0) && <p className="table-empty">No accounts yet.</p>}
              </tbody>
            </table>
            {(accounts && accounts.length > 0) && <div className="table-pagination">
              <Pagination pos={currentPage} total={totalPage} rows={state.account_limit} onPageChange={(eVal)=> {
                  setCurrentPage(eVal);
                  getAccounts(eVal, state.account_limit);
              }}/>
            </div>}
          </div>
          {showModal > -2 && (
              <AccountModal closeModal={closeModal} currentAccount={showModal >= 0  ? accounts[showModal] : null} saveModal={(obj)=>{
                if (showModal === -1) {
                  addAccountData(obj)(dispatch).then((res)=> {
                    getAccounts(currentPage, state.account_limit)
                    setToast(1);
                  }).catch(err=> {
                    setToast(2);
                  });
                } else {
                  updateAccountData(obj.id, {name: obj.name, key_contacts: obj.key_contacts, website: obj.website})(dispatch).then((res)=>{
                    getAccounts(currentPage, state.account_limit);
                    setToast(1);
                  }).catch(err => {
                    setToast(2);
                  });
                }
                closeModal();
            }}/>
          )}
          {showModal > -2 && <div className="modal-backdrop show" onClick={closeModal}></div>}
        </BodyWrapper>
        {toast != -1 && (<SaveToast closeToast={()=>{setToast(-1)}} toast={toast}/>)}
        </div>
    </div>
  </AccountsWrapper>)
}

const AccountsWrapper = styled.div`
  .bg-sidebar {
    background-color: #0A5987;
  }
  .col.body {
    background: #F2F4F7;
    overflow: auto;
    position: relative;
    height: 100vh;
  }
`
const BodyWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  .body-card {
    display: flex;
    cursor: pointer;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    background:  #FFF;
    border-radius: 16px;
    margin: 20px;
    table {
      p.table-empty {
        padding: 16px 24px;
        color: rgba(71, 84, 103, 0.50);
        font-family: Figtree;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin: 0px;
      }
      border-collapse: collapse;
      border-radius: 1em;
      overflow: hidden;
      width: 100%;
      padding: 12px;
      color: var(--gray-600, #475467);
      font-family: Figtree;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 150% */
      thead {
        background: #F9FAFB;
        tr {
          th {
            padding: 12px 24px;
          }
        } 
      }
      tbody {
        tr {
          border-bottom: 1px solid var(--gray-200, #EAECF0);
          td {
            padding: 16px 24px;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: #475467;
            h3 {
              color: var(--gray-900, #101828);
              font-family: Figtree;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; 
              margin: 0px;
            } 
            p {
              color: var(--gray-600, #475467);
              font-family: Figtree;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
            }
          }
          .account-action, .account-badges {
            display: flex;
          }
        }
      }
      margin-bottom: 0px;
    }
  }
  .filter-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 18px 20px;
    color: var(--gray-700, #344054);
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    input {
      border-radius: 8px;
      padding: 10px 14px;
      border: 1px solid var(--gray-300, #D0D5DD);
      background: var(--base-white, #FFF);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
  }
  .table-pagination {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 12px 24px;
  }
}
`
