import React, { useEffect, useState }  from "react";
import styled from "styled-components";

import Logomark from './../../../src/assets/images/Logomark.png';
import AvatarA from './../../../src/assets/images/AvatarA.png';
import logout from './../../../src/assets/images/logout2.png';
import { useDispatch } from "react-redux";
import useToken from "../../pages/auth/useToken";
import { getAccountData } from "../../action/api";

export default function Sidebar(props) {
    const {page} = props;
    const {accountId} = useToken();
    const [accountInfo, setAccountInfo] = useState({name: "", website: ""});
    const dispatch = useDispatch();
    useEffect(() => {
        getAccountData(accountId)(dispatch).then(res => {
            if (res && res.objects) {
                const account = res.objects[0];
                !!account && setAccountInfo({
                    name: account.name,
                    website: account.website
                });
            }
        })
    },  [])
    return (
        <SidebarWrapper  className="d-flex flex-column align-items-center align-items-sm-start text-white min-vh-100 pt-0">
            <div className="sidebar-header">
                <a href="/dashboard" className="return-link text-white text-decoration-none mx-3">
                    <i className="fa fa-arrow-left" aria-hidden="true"></i>
                    <img src={Logomark} alt="Logomark" />
                    <span>Return to mission control</span>
                </a>
                {/* <a href="/dashboard" className="logo-img d-flex align-items-center  mb-md-0 me-md-auto text-white text-decoration-none">
                    <img src={logo} alt="LOGO" />
                </a> */}
                <div className='sidebar-header-title'>
                    <h3 className="p-0 m-0 text-center">CLIENT DASHBOARD</h3>
                </div>
            </div>
            <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start px-3" id="menu">
                <li className="nav-item">
                    <a href="/client" className={`nav-link align-middle ${page === 'dashboard' ? 'p-active': ''}`}>
                        <i className="fa fa-list-alt" aria-hidden="true"></i>
                        <span className="d-none d-sm-inline">Dashboard</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a href="/interview-session" className={`nav-link align-middle ${page === 'interview-session' ? 'p-active': ''}`}>
                        <i className="fa fa-users" aria-hidden="true"></i>
                        <span className="d-none d-sm-inline">Interview Sessions</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a href="/fitness-criteria" className={`nav-link align-middle ${page === 'fitness-criteria' ? 'p-active': ''}`}>
                        <i className="fa fa-flag" aria-hidden="true"></i>
                        <span className="d-none d-sm-inline">Fitness Criteria</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a href="/interview-script" className={`nav-link align-middle ${page === 'interview-script' ? 'p-active': ''}`}>
                        <i className="fa fa-medkit"></i>
                        <span className="d-none d-sm-inline">Interview Scripts</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a href="/users" className={`nav-link align-middle ${page === 'users' ? 'p-active': ''}`}>
                        <i className="fa fa-users" aria-hidden="true"></i>
                        <span className="d-none d-sm-inline">Users</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a href="/client-settings" className={`nav-link align-middle ${page === 'settings' ? 'p-active': ''}`}>
                        <i className="fa fa-cog" aria-hidden="true"></i>
                        <span className="d-none d-sm-inline">Settings</span>
                    </a>
                </li>
            </ul>
            <hr/>
            <div className="pb-4 sidebar-bottom">
                <div className="d-flex align-items-center text-white">
                    <img src={AvatarA} alt="Avartar" width="40" height="40" className="rounded-circle"/>
                    <p className="d-none d-sm-inline">
                        {accountInfo.name} <br/>
                        <span>{accountInfo.website}</span>
                    </p>
                </div>
                <div className="d-flex align-items-center text-white text-decoration-none" onClick={()=> {
                localStorage.clear();
                window.location.href = '/';
            }}>
                    <img src={logout} className="out" />
                </div>
            </div>
        </SidebarWrapper>
    )
}

const SidebarWrapper = styled.div`
    width: 100%;
    width: 275px;
    hr {

    }
    .sidebar-header {
        position: relative;
        width: 100%;
        a.logo-img{
            justify-content: center;
            padding-top: 24px;
            padding-bottom: 24px;
        }
        a.return-link {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-shrink: 0;
            align-self: stretch;
            background: #007AAB;
            padding: 4px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
            color: #DAF1FA;
            img {
                width: 22px;
                height: 22px;
            }
            span {
                font-family: Figtree;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 18px; /* 150% */
            }
        }
        .sidebar-header-title {
            background: #007AAB;
            padding: 4px 16px;
            h3 {
                cursor: normal;
                color: #DAF1FA;
                font-family: Figtree;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px; /* 150% */
                letter-spacing: 1.12px;
            }
            margin-top: 116px;
            margin-bottom: 24px;
        }
    }
    ul {
        width: 100%;
        li {
            width: 100%;
            a {
                padding: 12px;
                color: white;
                font-family: Figtree;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px; /* 125% */
                span {
                    margin-left: 10px;  
                }
                &:hover, &:active, &:focus, &.p-active {
                    border-radius: 6px;
                    background: #007AAB;
                    color: white;
                }
            }
            margin-bottom: 8px;
        }
    }
    .sidebar-bottom {
        display: flex;
        border-top: 1px solid var(--primary-700, #007AAB);
        justify-content: space-between;
        width: 100%;
        padding: 5px 25px;
        padding-top: 20px;
        div {
            img {
                width: 40px;
                height: 40px;
            }
            p {
                color:  #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px; /* 142.857% */
                margin: 0px;
                margin-left: 12px;
                margin-right: -12px;
                span {
                    color: #CAEBF9;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px; /* 142.857% */
                }
            }
            img.out {
                width: 32px;
                height: 32px;
                margin-left: 20px;
                &:hover {
                    background: #fff5;
                    border-radius: 5px;
                    cursor: pointer;
                    // border: solid 2px #f00;
                    transition: background .5s;
                }
            }
        }
        i {
            line-height: 40px;
        }
    }
`
