import http from "../config/http-common";
import { Buffer } from 'buffer';

const getToken = (email, password) => {
  const credentials = Buffer.from(`${email}:${password}`).toString('base64');
  // const credentials = new Buffer(`${email}:${password}`).toString('base64');
  const authorizationHeader = `Basic ${credentials}`;

  return http.get(`/v1/users/token/54bb2165-71e1-41a6-af3e-7da4a0e1e2c1`,{headers: {
    'Authorization': authorizationHeader,
  }});
};

// =============== Accounts ===============

const getAccountsData = (page = 0, rows = 10) => {
  return http.get(`/v1/accounts?page=${page}&rows=${rows}`);
};

const getAccount = (id) => {
  return http.get(`/v1/accounts/${id}`);
};

const postAccount = (obj) => {
  console.log("-post");
  return http.post(`/v1/accounts`, {
    ...obj,
  });
};

const putAccount = (id, obj) => {
  return http.put(`/v1/accounts/${id}`, {
    ...obj,
  });
};

// =============== Users ===============

const getUsers = (page = 0, rows = 10, company) => {
  if (company)
    return http.get(`/v1/users?page=${page}&rows=${rows}&query={"$eq": {"account_id": "${localStorage.getItem('account_id')}"}}`);
  return http.get(`/v1/users?page=${page}&rows=${rows}`);
};
const getUser = (id) => {
  return http.get(`/v1/users/${id}`);
};
const postUser = (obj) => {
  return http.post(`/v1/users`, {
    ...obj,
  });
};
const putUser = (id, obj) => {
  return http.put(`/v1/users/${id}`, {
    ...obj,
  });
};
const deleteUser = (id) => {
  return http.delete(`/v1/users/${id}`);
};

// =============== Interview Sessions ===============

const getInterviewSessions = (id, page = 0, rows = 10) => {
  return http.get(`/v1/interview-scripts/${id}/interview-sessions?page=${page}&rows=${rows}`);
};
const postInterviewSessions = (id, obj) => {
  return http.post(`/v1/interview-scripts/${id}/interview-sessions`, {
    ...obj,
  });
}
const getInterviewSession = (id, id2) => {
  return http.get(`/v1/interview-scripts/${id}/interview-sessions/${id2}`);
};
const deleteInterviewSession = (id) => {
  return http.delete(`/v1/interview-sessions/${id}`);
};

// =============== Fitness Criteria ===============

const getFitnessCriteriaList = (page = 0, rows = 10, company) => {
  if (company)
    return http.get(`/v1/fitness-criteria?page=${page}&rows=${rows}&query={"$eq": {"account_id": "${localStorage.getItem('account_id')}"}}`);
  return http.get(`/v1/fitness-criteria?page=${page}&rows=${rows}`);
};
const getFitnessCriteria = (id, page = 0, rows = 10) => {
  return http.get(`/v1/fitness-criteria/${id}/criterion?page=${page}&rows=${rows}`);
};
const postFitnessCriteria= (obj) => {
  return http.post(`/v1/fitness-criteria`, {
    ...obj,
  });
};
const postCriteria= (id, obj) => {
  return http.post(`/v1/fitness-criteria/${id}/criterion`, {
    ...obj,
  });
};
const putCriteria= (id, id2, obj) => {
  return http.put(`/v1/fitness-criteria/${id}/criterion/${id2}`, {
    ...obj,
  });
};
const putFitnessCriteria = (id, obj) => {
  return http.put(`/v1/fitness-criteria/${id}`, {
    ...obj, 
  });
};
const deleteFitnessCriteria = (id) => {
  return http.delete(`/v1/fitness-criteria/${id}`);
};
const deleteCriteria = (id, id2) => {
  return http.delete(`/v1/fitness-criteria/${id}/criterion/${id2}`);
};

// =============== Interview Scripts ===============

const getInterviewScripts = (page = 0, rows = 10, company) => {
  if (company)
    return http.get(`/v1/interview-scripts?page=${page}&rows=${rows}&query={"$eq": {"account_id": "${localStorage.getItem('account_id')}"}}`);
  return http.get(`/v1/interview-scripts?page=${page}&rows=${rows}`);
};
const getInterviewScriptDesign = (id, page = 0, rows = 10) => {
  return http.get(`/v1/interview-scripts/${id}/designs?page=${page}&rows=${rows}`);
};
const getInterviewScriptQuestions = (id, page = 0, rows = 10) => {
  return http.get(`/v1/interview-scripts/${id}/questions?page=${page}&rows=${rows}`);
};
const getInterviewScriptOption= (id, id2) => {
  return http.get(`/v1/interview-scripts/${id}/options/${id2}`);
};
const getInterviewScriptOptions = (id, page = 0, rows = 10) => {
  return http.get(`/v1/interview-scripts/${id}/options?page=${page}&rows=${rows}`);
};
const postInterviewScriptOption= (id, obj) => {
  return http.post(`/v1/interview-scripts/${id}/options`, {
    ...obj,
  });
};
const putInterviewScriptOptions = (id, id2, obj) => {
  return http.put(`/v1/interview-scripts/${id}/options/${id2}`, {
    "has_conversation_page": obj.has_conversation_page,
    "has_fitness_score": obj.has_fitness_score,
    "has_follow_up": obj.has_follow_up,
    "has_instant_scheduling": obj.has_instant_scheduling,
    "has_live_takeover": obj.has_live_takeover
  });
};
const postInterviewScripts= (obj) => {
  return http.post(`/v1/interview-scripts`, {
    ...obj,
  });
};
const postInterviewScriptDesign = (id, obj) => {
  return http.post(`/v1/interview-scripts/${id}/designs`, {
    ...obj, 
  });
};
const putInterviewScriptDesign = (id, id2, obj) => {
  return http.put(`/v1/interview-scripts/${id}/designs/${id2}`, {
    ...obj, 
  });
};
const postInterviewScriptQuestions = (id, obj)  => {
  return http.post(`/v1/interview-scripts/${id}/questions`, {
    ...obj, 
  });
}
const putInterviewScriptQuestions = (id, id2, obj) => {
  return http.put(`/v1/interview-scripts/${id}/questions/${id2}`, {
    ...obj, 
  });
};
const deleteInterviewScriptQuestion = (id, id2) => {
  return http.delete(`/v1/interview-scripts/${id}/questions/${id2}`);
};
const deleteInterviewScripts = (id) => {
  return http.delete(`/v1/interview-scripts/${id}`);
};

// =============== Incentive ===============

const postIncentive= (obj) => {
  return http.post(`/v1/incentives`, {
    ...obj,
  });
};
const getIncentiveList = (pages = 0, rows = 10) => {
  return http.get(`/v1/incentives?page=${pages}&rows=${rows}`);
};
const getIncentive = (id) => {
  return http.get(`/v1/incentives/${id}`);
};
const putIncentive = (id, obj) => {
  return http.put(`/v1/incentives/${id}`, {
    ...obj,
  });
};
const deleteIncentive = (id) => {
  return http.delete(`/v1/incentives/${id}`);
};

const ApiServices = {
  getToken,
  getAccountsData,
  getAccount,
  postAccount,
  putAccount,
  getUsers,
  getUser,
  postUser,
  putUser,
  deleteUser,
  getFitnessCriteria,
  getFitnessCriteriaList,
  postFitnessCriteria,
  postCriteria,
  putCriteria,
  putFitnessCriteria,
  deleteFitnessCriteria,
  deleteCriteria,
  getInterviewScriptDesign,
  getInterviewScriptOption,
  getInterviewScriptOptions,
  postInterviewScriptOption,
  putInterviewScriptOptions,
  getInterviewScriptQuestions,
  getInterviewScripts,
  postInterviewScripts,
  deleteInterviewScripts,
  postInterviewScriptDesign,
  putInterviewScriptDesign,
  postInterviewScriptQuestions,
  putInterviewScriptQuestions,
  deleteInterviewScriptQuestion,
  getInterviewSessions,
  getInterviewSession,
  deleteInterviewSession,
  postInterviewSessions,
  postIncentive,
  putIncentive,
  getIncentive,
  deleteIncentive,
  getIncentiveList,
};

export default ApiServices;
