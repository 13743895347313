import React from "react"
import styled from "styled-components"
import refresh from './../../assets/images/refresh-cw-05.png';
import filter from './../../assets/images/filter-lines.png';
import save from './../../assets/images/save-01.png';
import arrowLeft from './../../assets/images/arrow-left.png';
import arrowRight from './../../assets/images/arrow-right.png';

// import code from './../assets/images/code-snippet.svg';
const IconWrap = (props) => {
    switch(props.icon) {
        case 'eye':
            return (<i className="fa fa-eye" aria-hidden="true"></i>);
        case 'plus':
            return (<i className="fa fa-plus" aria-hidden="true"></i>);
        case 'refresh':
            return (<img src={refresh} alt="referesh"/>);
        case 'save':
            return(<img src={save} alt="save"/>);
        case 'filter':
            return(<img src={filter} alt="filter"/>);
        case 'arrowLeft':
            return(<img src={arrowLeft} alt="arrowLeft"/>);
        case 'arrowRight':
            return(<img src={arrowRight} alt="arrowRight"/>);
        default: 
            return;
    }
}
export default function Button(props) {
    const { title, icon, outline, onClickBtn, red } = props;
    return (<ButtonWrapper className={outline ? 'outline' : red ? 'red' : ''} onClick={onClickBtn}>
        {icon != null && <IconWrap icon={icon} /> }
        {title} 
    </ButtonWrapper>)
}
const ButtonWrapper = styled.div`
    background: #1693C7;
    padding: 10px 16px;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    display: inline-block;
    font-family: Figtree;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    i, img  {
        margin-right: 8px; 
    }
    &.outline {
        background: white;
        color: #344054;
        border: 1px solid #D0D5DD;
        margin-right: 10px;
    }
    &.red {
        background: #D92D20;
    }
`
