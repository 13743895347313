import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import pen1 from './../../../assets/images/pen1.png';
import trash from './../../../assets/images/trash-02.png';
import Header from "../../../components/header";
import Sidebar from "../../../components/client/sidebar";
import Button from "../../../components/basics/button";
import Badge from "./../../../components/basics/badge";
import Pagination from "../../../components/basics/pagination";
import { addInterviewScriptData, deleteInterviewScriptsData, getInterviewScriptDesign, getInterviewScriptsData } from "../../../action/api";
import InterviewScriptModal from "../../../components/modals/script";
import SubInterviewScript from "./sub-interview-script";
import CancelModal from "../../../components/modals/cancel";
import SaveToast from "../../../components/toast/save";

export default function InterviewScript(props) {
  const state = useSelector((state) => state.apiInterviewScriptsReducer);
  const dispatch = useDispatch();
  const [toast, setToast] = useState(-1);
  const [currentPage, setCurrentPage] = useState(state.interview_script_page || 1);
  const [totalPage, setTotalPage] = useState(state.interview_script_total);
  const [selectedItem, setSelectedItem] = useState({});
  const [design, setDesign] = useState([])
  const [scripts, setScripts] = useState([...state.interview_script_list]);
  useEffect(() => {
    getScripts(state.interview_script_page, state.interview_script_limit)
  }, [dispatch]);
  useEffect(() => {
    if (toast) {
      setTimeout(() => {
        setToast(-1)
      }, 5000);
    }
  }, [toast]);
  const getScripts = (pages, rows) => {
    getInterviewScriptsData(pages, rows, true)(dispatch).then((res) => {
      if (res && res.data.objects)
        setScripts([...res.data.objects])
      if (res && res.data.total)
        setTotalPage(res.data.total)
    });
  }
  const [search, setSearch] = useState('');
  const [showModal, setShowModal] = useState(-2);
  const [delModal, setDelModal] = useState('');
  const closeModal = () => {
      setShowModal(-2);
  };

  const editAccount = (acc) => {
    setSelectedItem(acc);
    getInterviewScriptDesign(acc.id)(dispatch).then(res => {
      console.log('design-"', res)
      if(res && res.objects) {
        setDesign([...res.objects])
        setToast(1);
      }else {
      }
      setShowModal(-3)
    }).catch(err=> {
      setToast(2);
    })
  }
  async function delInterviewscript(id) {
    deleteInterviewScriptsData(id)(dispatch).then((res) => {
      if ((totalPage - 1) < currentPage * state.interview_script_limit)
        getScripts(currentPage > 1 ? currentPage - 1 : currentPage, state.interview_script_limit)
      else 
        getScripts(currentPage, state.interview_script_limit)
      setDelModal('')
    });
  }
  return showModal != -3 ?(<InterviewScriptWrapper className='container-fluid'>
    <div className="row flex-nowrap">
      <div className="col-auto px-0 bg-sidebar">
        <Sidebar page="interview-script"/>
      </div>
       <div className="col body p-0">
        <Header title={'Interview Scripts'}  onPrevMain={(content = 0)=>{
          setShowModal(-1);  
          getScripts(currentPage, state.interview_script_limit);
        }}  />
        <BodyWrapper>
          <div className="filter-wrapper">
            <Button title="Filters" icon="filter" outline/>
            <input type="text" value={search}  onChange={(e)=>{setSearch(e.target.value);}}placeholder='Search'/>
          </div>
          <div className="body-card">
            <table>
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Status</th>
                  <th scope="col">Comments</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody> 
                {scripts &&  scripts.length > 0  && scripts.map((account,index) => (
                  <tr key={'account-' + index}>
                    <td className="account-company">
                      <h3>{account.name}</h3>
                    </td>
                    <td>
                      <Badge title={account.status == '' ? 'active': account.status} border="#D0D5DD" fill="none" color="#344054" circle={account.status == '' ? '#17B26A':'#F79009'}/>
                    </td>
                    <td>{account.comments}</td>
                    <td className="account-action">
                      <img src={trash} alt="" className="trash-img"  onClick={()=> {setDelModal(account.id)}}/>
                      <img src={pen1} alt="pen" onClick={()=> {editAccount(account);}}/> 
                    </td>
                  </tr>
                ))}
                {(scripts.length == 0 ) && <p className="table-empty">
                  Click the “Add Interview Script” button to add interview script
                  </p>}
              </tbody>
            </table>  
            {scripts && scripts.length > 0 && <div className="table-pagination">
            <Pagination pos={currentPage} total={totalPage} rows={state.interview_script_limit} onPageChange={(eVal)=> {
                  setCurrentPage(eVal)
                  getScripts(eVal, state.interview_script_limit)
              }}/>
            </div>}
          </div>
        </BodyWrapper>
        {delModal != '' && (
              <CancelModal closeModal={()=>{setDelModal('')}} title="Delete interview script" description="Are you sure you want to delete this interview script? There is no going back!" saveModal={()=>{
                delInterviewscript(delModal);
            }}/>
          )}
        {delModal != '' && <div className="modal-backdrop show" onClick={()=>{setDelModal('')}}></div>}
      </div>
      {showModal > -2 && (
        <InterviewScriptModal closeModal={closeModal} saveModal={(obj)=>{
            if (showModal === -1) {
              addInterviewScriptData(obj)(dispatch).then((res)=>{
                getScripts(1, state.interview_script_limit);
                setToast(1);
              }).catch(err => {
                setToast(2)
              });
            }
            closeModal();
        }}/>
      )}
      {showModal > -2 && <div className="modal-backdrop show" onClick={closeModal}></div>}
    </div>
    {toast != -1 && (<SaveToast closeToast={()=>{setToast(-1)}} toast={toast}/>)}
  </InterviewScriptWrapper>):<SubInterviewScript design={design}  item={selectedItem} onPrevSubInterviewScript={()=>{
    setShowModal(-2);
  }}/>
}

const InterviewScriptWrapper = styled.div`
  .bg-sidebar {
    background-color: #0A5987;
  }
  .col.body {
    background: #F2F4F7;
    overflow: auto;
    position: relative;
    height: 100vh;
  }
`
const BodyWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  .body-card {
    display: flex;
    cursor: pointer;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    background:  #FFF;
    border-radius: 16px;
    margin: 20px;
    table {
      border-collapse: collapse;
      border-radius: 1em;
      overflow: hidden;
      width: 100%;
      padding: 12px;
      color: var(--gray-600, #475467);
      font-family: Figtree;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 150% */
      thead {
        background: #F9FAFB;
        tr {
          th {
            padding: 12px 24px;
          }
        } 
      }
      tbody {
        tr {
          border-bottom: 1px solid var(--gray-200, #EAECF0);
          td {
            padding: 16px 24px;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: #475467;
            h3 {
              color: var(--gray-900, #101828);
              font-family: Figtree;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; 
              margin: 0px;
            } 
            p {
              color: var(--gray-600, #475467);
              font-family: Figtree;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
            }
          }
          .account-action, .account-badges {
            display: flex;
          }
        }
      }
      margin-bottom: 0px;
    }
  }
  .filter-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 18px 20px;
    color: var(--gray-700, #344054);
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    input {
      border-radius: 8px;
      padding: 10px 14px;
      border: 1px solid var(--gray-300, #D0D5DD);
      background: var(--base-white, #FFF);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
  }
  .table-pagination {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 12px 24px;
  }
}
p.table-empty {
  padding: 16px 24px;
  color: rgba(71, 84, 103, 0.50);
  font-family: Figtree;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin: 0px;
}
.trash-img {
  width: 20px;
  height: 20px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 12px;
}
`
