export function getErrorMessage(errorMsg) {
  if (errorMsg.text !== undefined) {
    if (errorMsg.text.includes("'required' tag"))
      return 'A required field is missing data. Please add the the required data before clicking Save.';
    else if (errorMsg.text.includes("'min' tag"))
      return 'Answers are required to be four or more characters long.';
  } else if (errorMsg.cta_standard_text !== undefined) {
    if (errorMsg.cta_standard_text.includes("'required' tag"))
      return 'Standard call to action field is missing data. Please add the the required data before clicking Save.';
    else if (errorMsg.cta_standard_text.includes("'min' tag"))
      return 'Standard call to action is required to be four or more characters long.';
  } else if (errorMsg.fitness_criteria_id !== undefined) {
    return 'Fitness Criteria field is missing data. Please add the the required data before clicking Save.';
  } else if (errorMsg.fitness_criteria_id !== undefined) {
    return 'Fitness Criteria field is missing data. Please add the the required data before clicking Save.';
  } else if (errorMsg.experience !== undefined) {
    return 'Interview experience field is missing data. Please add the the required data before clicking Save.';
  } else if (errorMsg.style !== undefined) {
    return 'Style field is missing data. Please add the the required data before clicking Save.';
  } else if (errorMsg.cta_secondary_text !== undefined) {
    if (errorMsg.cta_secondary_text.includes("'required' tag"))
      return 'CTA Secondary text field is missing data. Please add the the required data before clicking Save.';
    else if (errorMsg.cta_secondary_text.includes("'min' tag"))
      return 'CTA Secondary text field is required to be four or more characters long.';
  } else if (errorMsg.interview_script_incentive_text !== undefined) {
    if (errorMsg.interview_script_incentive_text.includes("'required' tag"))
      return 'Interview script incetive text field is missing data. Please add the the required data before clicking Save.';
    else if (errorMsg.interview_script_incentive_text.includes("'min' tag"))
      return 'Interview script incetive text field is required to be four or more characters long.';
  } else if (errorMsg.interview_script_completed_text !== undefined) {
    if (errorMsg.interview_script_completed_text.includes("'required' tag"))
      return 'Interview script completed text field is missing data. Please add the the required data before clicking Save.';
    else if (errorMsg.interview_script_completed_text.includes("'min' tag"))
      return 'Interview script completed text field is required to be four or more characters long.';
  } else if (errorMsg.error !== undefined) {
    return 'Fitness Criteria name already exists. Enter a unique name then save.';
  }
}
