import React from "react"
import styled from "styled-components"

import Sidebar from "./../components/sidebar";
import Header from "./../components/header";

export default function Dashboard(props) {
  return (<DashboardWrapper className='container-fluid'>
    <div className="row flex-nowrap">
      <div className="col-auto px-0 bg-sidebar">
        <Sidebar page="dashboard"/>
      </div>
      <div className="col body p-0">
        <Header title={'Dashboard'} onPrevMain={()=>{}} />
      </div>
    </div>
  </DashboardWrapper>)
}
const DashboardWrapper = styled.div`
.bg-sidebar {
    background-color: #0A5987;
  }
  .col.body {
    background: #F2F4F7;
    overflow: auto;
    position: relative;
    height: 100vh;
  }
`
