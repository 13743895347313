import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Button from "../basics/button";
import InputTags from "../tags";

export default function AccountModal(props) {
  const { closeModal, currentAccount, saveModal } = props;
  const [flag, setFlag] = useState(-1);
  const [addCompanyName, setAddCompanyName] = useState(currentAccount ? currentAccount.name : '');
  const [addCompanyWebsite, setAddCompanyWebsite] = useState(currentAccount ? currentAccount.website :'');
  const [addCompanyEmail, setAddCompanyEmail] = useState(currentAccount ? currentAccount.key_contacts :[]);
  useEffect(() => {
    if (flag === 0 ) {
      if (addCompanyName.length < 4 || !isValidUrlFormat(addCompanyWebsite) || !checkEmailList(addCompanyEmail))
        setFlag(1);
      else {
        saveModal({
          ...currentAccount,
          name: addCompanyName,
          key_contacts: [...addCompanyEmail],
          website:addCompanyWebsite
        })
      }
    }
  }, [flag])
  const isValidUrlFormat = (url) => {
    return true;
    // Regular expression for basic URL validation
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlRegex.test(url);
  };
  const validateEmail = (email) => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const checkEmailList = (list) => {
    console.log('--checkemail--',list)
    if(list.length === 0 || list.length > 3) {
      return false;
    }
    for(let i = 0; i < list.length; i ++) {
      console.log(">>", list[i], validateEmail(list[i]))
      if (!validateEmail(list[i]) ) {

        return false;
      }
    }
    return true;
  }
  return (<AccountModalWrapper>
    <div className="modal fade show" tabIndex={-1} role="dialog" style={{ display: 'block' }}>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <form>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">{currentAccount ? 'Edit' : 'Add  new'} account</h5>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor={"modal_title"}>Company Name</label>
                <input type="text" className={`form-control ${flag === 1 && addCompanyName.length < 4  && 'form-validation'}`} id="modal_title" aria-describedby="modal_title_input" placeholder="Enter company name" value={addCompanyName} onChange={(e)=> {setAddCompanyName(e.target.value)}} />
                {flag === 1 && addCompanyName.length < 4  && <p className="reuiqed-html">This field required and over 4 letters.</p> }
              </div>
              <div className="form-group">
                <label htmlFor="modal_to">Company Website</label>
                <input type="text" className={`form-control ${flag === 1 && !isValidUrlFormat(addCompanyWebsite)  && 'form-validation'}`} id="modal_to" placeholder="Enter company website" value={addCompanyWebsite}  onChange={(e)=> {setAddCompanyWebsite(e.target.value)}} />
                {flag === 1 && !isValidUrlFormat(addCompanyWebsite)  && <p className="reuiqed-html">This field is in URL(https://, http://) format.</p> }
              </div>
            </div>
            <div className="modal-footer">
              <h3>Add key contacts</h3>
              <p>Add up to 3 email addresses of companies key contacts.</p>
              <div className="form-group">
                <InputTags keys={addCompanyEmail || []} onChangeKeys={(arr) => {
                  console.log('-onchangekey-', arr)
                  setAddCompanyEmail([...arr])
                }}/>
                {flag === 1 && !checkEmailList([...addCompanyEmail])  && <p className="reuiqed-html">This field need only emails.</p> }
                  {/* <label htmlFor={"modal_email_input"}>Email address(es)</label>
                  <input type="email" className="form-control" id="modal_email" aria-describedby="modal_email_input" placeholder="Add email address" value={addCompanyEmail}  onChange={(e)=> {setAddCompanyEmail(e.target.value)}} /> */}
              </div>
              <div className="modal-footer-btn">
                <Button title="Cancel" outline onClickBtn={closeModal} />
                <Button title={!currentAccount ? "Add Account" : "Edit Account"} onClickBtn={()=>{
                  setFlag(0);
                }} />
              </div>
            </div>
          </div>
        </form> 
      </div>
    </div>
  </AccountModalWrapper>)
}

const AccountModalWrapper = styled.div`
  .modal.fade {
    background:none;
    .modal-dialog-centered {
      display: grid;
    }
    form .form-control.form-validation {
      border-color: red;
      margin-bottom: 3px;
    }
    form .form-group {
      label {
        color:  #344054;
        font-family: Figtree;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        margin-bottom:  6px;
      }
      input, textarea {
        border-radius: 8px;
        border: 1px solid #D0D5DD;
        background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: #667085;
        font-family: Figtree;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */  
        margin-bottom: 16px;
      }
    }
    .modal-footer {
      display: block;
      h3 {
        color: var(--gray-900, #101828);
        font-family: Figtree;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
      }
      p {
        color: var(--gray-600, #475467);
        font-family: Figtree;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
      .modal-footer-btn {
        display: flex;
        justify-content: flex-end;
      }
    }
    .button-copy {
      background: #1693C7;
      padding: 10px 16px;
      border-radius: 8px;
      color: white;
      cursor: pointer;
      display: inline-block;
      font-family: Figtree;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      i {
        margin-right: 8px; 
      }
      &.email {
        background: white;
        color: #344054;
        border: 1px solid #D0D5DD;
        margin-right: 10px;
      }
    }
  }
  p.reuiqed-html {
    color: red!important;
    font-size: 12px;
  }
`
