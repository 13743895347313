import React, { useState, useEffect }  from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import 'react-tagsinput/react-tagsinput.css';
import { addInterviewScriptOption, getInterviewScriptOptions, updateInterviewScriptOptions } from "../../../action/api";
import SaveToast from "../../../components/toast/save";

export default function Options(props) {
    const { item, flag, flagFunc } = props;
    const [toast, setToast] = useState(-1);
    const [option, setOption] = useState({
        "has_conversation_page": false,
        "has_fitness_score": false,
        "has_follow_up": false,
        "has_instant_scheduling": false,
        "has_live_takeover": false
      });
    const state = useSelector((state) => state.apiReducer);
    const dispatch = useDispatch();
    useEffect(() => {
      getInterviewScriptOptions(item.id)(dispatch).then (res => {
        if(res && res.objects && res.objects.length > 0 ) {
            setOption({...res.objects[0]})
        }
      });
    }, [dispatch]); 
    useEffect(() => {
        if (toast) {
          setTimeout(() => {
            setToast(-1)
          }, 5000);
        }
      }, [toast])
    useEffect(() => {
        if (flag == 3) {
            if (option.id) {
                updateInterviewScriptOptions(item.id, option.id, option)(dispatch);
                setToast(1)
            } else {
                addInterviewScriptOption(item.id , option)(dispatch)
                setToast(1)
            }
            flagFunc();
        }
    },[flag])
     return (<OptionsWrapper>
        <InterviewScriptHeader>
            <h2>
                Options<br/>
            </h2>
        </InterviewScriptHeader>
        <InterviewScriptBody>
            <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault1"   checked={option.has_conversation_page} onChange={(e)=> {
                    setOption({...option, has_conversation_page: e.target.checked})
                }}/>
                <label className="form-check-label" htmlFor="flexSwitchCheckDefault1">Enable conversation page</label>
            </div>
            <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault2" checked={option.has_live_takeover} onChange={(e)=> {
                    setOption({...option, has_live_takeover: e.target.checked})
                }}/>
                <label className="form-check-label" htmlFor="flexSwitchCheckDefault2">Enable live take over</label>
            </div>
            <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault3" checked={option.has_instant_scheduling} onChange={(e)=> {
                    setOption({...option, has_instant_scheduling: e.target.checked})
                }}/>
                <label className="form-check-label" htmlFor="flexSwitchCheckDefault3">Enable instant scheduling</label>
            </div>
            <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault4" checked={option.has_fitness_score} onChange={(e)=> {
                    setOption({...option, has_fitness_score: e.target.checked})
                }}/>
                <label className="form-check-label" htmlFor="flexSwitchCheckDefault4">Enable prospect fitness score</label>
            </div>
            {/* <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault5" checked={option.has} onChange={(e)=> {
                    setOption({...option, has_conversation_page: e.target.checked})
                }}/>
                <label className="form-check-label" htmlFor="flexSwitchCheckDefault5">Generate interview summary</label>
            </div> */}
            <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault6" checked={option.has_follow_up} onChange={(e)=> {
                    setOption({...option, has_follow_up: e.target.checked})
                }}/>
                <label className="form-check-label" htmlFor="flexSwitchCheckDefault6">Generate personalized follow-up</label>
            </div>
        </InterviewScriptBody>
        {toast != -1 && (<SaveToast closeToast={()=>{setToast(-1)}} toast={toast}/>)}
    </OptionsWrapper>)
}

const OptionsWrapper = styled.div`
    position: relative;
    padding: 0px;
    width: 100%;
`
const InterviewScriptHeader = styled.div`
    padding: 20px 24px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    h2 {
        color: #101828;
        font-family: Figtree;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 155.556% */
        margin-bottom: 0px;
        span {
            color: #475467;
            font-family: Figtree;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
        }
    }
    .question-btn {
        padding: 10px 16px;
        height: 100%;
        border-radius: 8px;
        border: 1px solid #D0D5DD;
        background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: #344054;
        font-family: Figtree;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
        i {
            margin-right: 5px;
        }
    }

    border-bottom: 1px solid #EAECF0;
`
const InterviewScriptBody= styled.div`
    padding: 20px 24px;
    .form-check {
        padding-bottom: 6px;
        label {
            padding-bottom: 8px;
        }
    }
    .form-switch .form-check-input:checked{
        background-color: #1693C7!important;
        border-color: #1693C7!important;
    }
`
