import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Sidebar from "../../../components/client/sidebar";
import Header from "../../../components/client/header";
import check from '../../../assets/images/check.png';

export default function ClientSettings(props) {
  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
      setShowModal(true);
  };
  const closeModal = () => {
      setShowModal(false);
  };
  useEffect(() => {
      const keyDownHandler = (event) => {
        console.log('User pressed1: ', event.key);
        setShowModal(true); 

        if (event.key === 'Enter') {
          event.preventDefault();
        }
      }
      document.addEventListener('keydown', keyDownHandler);
      return () => {
        document.removeEventListener('keydown', keyDownHandler);
      };
    });
    return (<ClientSettingsWrapper className='container-fluid'>
    <div className="row flex-nowrap">
      <div className="col-auto px-0 bg-sidebar">
        <Sidebar page="settings"/>
      </div>
      <div className="col body p-0">
        <Header title={'Settings'} subItem={null}  onPrevMain={()=>{
        }}/>
        <BodyWrapper>
          <div className="card">
            <h2>Topic Page Data Sources</h2>
            <div className="setting-card-body">
              <p>Connect PerceptivePanda to your favourite tools to access your gated content and rapidly customize workflows</p>
              <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" id="checkMailchimp" checked data-toggle="modal" data-target="#exampleModal" onClick={openModal}/>
                <label className="form-check-label" htmlFor="checkMailchimp">
                  Mailchimp
                </label>
              </div>
              <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" id="checkHubspot" />
                <label className="form-check-label" htmlFor="checkHubspot">
                  Hubspot
                </label>
              </div>
              <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" id="checkSalesforce" />
                <label className="form-check-label" htmlFor="checkSalesforce">
                  Salesforce
                </label>
              </div>
              <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" id="checkOutreach" />
                <label className="form-check-label" htmlFor="checkOutreach">
                  Outreach.io
                </label>
              </div>
            </div>
            {showModal && (
            <div className={`modal fade show`} id="exampleModal" tabIndex={-1} role="dialog" style={{ display: 'block' }}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Add Mailchimp account</h5>
                            <p>
                              Allow PerceptivePanda to use your MailChimp content to create contextually relevant topic pages
                            </p>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label htmlFor={"mailchimp_email"}>Email</label>
                                    <input type="email" className="form-control" id="mailchimp_email" aria-describedby="mailchimp_email" placeholder="Enter your email" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="modal_to">Password</label>
                                    <input type="password" className="form-control" id="modal_to" placeholder="" />
                                </div>
                            </form> 
                        </div>
                        <div className="modal-footer">
                            <div className="button-copy email" data-dismiss="modal"  onClick={closeModal}>Cancel</div>
                            <div className="button-copy">Save</div>
                        </div>
                    </div>
                </div>
            </div>
            )}
            {showModal && <div className="modal-backdrop show" onClick={closeModal}></div>}
          </div>
        </BodyWrapper>
      </div>
    </div>
  </ClientSettingsWrapper>)
}

const ClientSettingsWrapper = styled.div`
  .bg-sidebar {
    background-color: #0A5987;
  }
  .col.body {
    background: #F2F4F7;
    overflow: auto;
    position: relative;
  }
`
const BodyWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  .card {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid #EAECF0;
    background:  #FFF;
    border-radius: 16px;
    margin: 20px;
    h2 {
      padding: 21px 24px;
      color: #101828;
      width: 100%;
      font-family: Figtree;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px; /* 155.556% */
      border-bottom: 1px solid #EAECF0;
    }
    div.setting-card-body {
      padding: 20px 24px;
      p {
        color: #344054;
        font-family: Figtree;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
      }
    }
    .form-check-input:checked[type=checkbox] {
      border: 1px solid var(--primary-600, #1693C7);
      background: var(--primary-50, #E7F7FF);
      background-image: url(${check});
      background-size: contain;
    }
  }
  .modal.fade {
    .modal-header {
      display:flex;
      flex-direction: column;
      padding: 20px 30px;
      h5 {
        text-align: left;
        width: 100%;
        color: #101828;
        font-family: Figtree;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 155.556% */
      }
      p {
        color: #475467;
        font-family: Figtree;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        margin-bottom: 0px;
      }

    }
    .modal-body {
      padding: 20px 30px;
      input[type=password] {
        height: 38px;
        padding: 6px;
      }
    }
    form .form-group {
        label {
            color:  #344054;
            font-family: Figtree;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
            margin-bottom:  4px;
        }
        input, textarea {
            border-radius: 8px;
            border: 1px solid #D0D5DD;
            background: #FFF;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            color: #667085;
            font-family: Figtree;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */  
        }
    }
    .button-copy {
      background: #1693C7;
      padding: 10px 16px;
      border-radius: 8px;
      color: white;
      cursor: pointer;
      display: inline-block;
      font-family: Figtree;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      i  {
          margin-right: 8px; 
      }
      &.email {
          background: white;
          color: #344054;
          border: 1px solid #D0D5DD;
          margin-right: 5px;
      }
  }
}
`
