import React, {useState}  from "react";
import styled from "styled-components";

import radio_check from '../../../assets/images/radio_check.svg';
import trash from '../../../assets/images/trash-02.png';
import arrow_up from '../../../assets/images/arrow-up.png';
import arrow_down from '../../../assets/images/arrow-down.png';
import 'react-tagsinput/react-tagsinput.css';
import TagsInput from "react-tagsinput";
import check from '../../../assets/images/check.png';

export default function Notification(props) {
    const [tagsEmail, setEmailTags] = useState(['johndoe@getlayers.io','sara@getlayers.io','george@getlayers.io']);
    const [tagsSlack, setSlackTags] = useState(['C05U4SJEP9R','U02DLD7B3J8','']);
  return (<NotificationWrapper>
    <InterviewScriptHeader>
        <h2>
            Notification Options<br/>
        </h2>
    </InterviewScriptHeader>
    <InterviewScriptBody>
        <div className="form-check">
            <input className="form-check-input" type="checkbox" value="" id="Email" checked/>
            <label className="form-check-label" htmlFor="Email">
                Email
            </label>
            <TagsInput value={tagsEmail} onChange={()=>{console.log('tagsEmail')}} />
        </div>
        <div className="form-check">
            <input className="form-check-input" type="checkbox" value="" id="Slack" />
            <label className="form-check-label" htmlFor="Slack">
                Slack
            </label>
            <TagsInput value={tagsSlack} onChange={()=>{console.log('tagsSlack')}} />
        </div>
        <div className="form-check">
            <input className="form-check-input" type="checkbox" value="" id="CRM" />
            <label className="form-check-label" htmlFor="CRM">
                CRM
            </label>
        </div>
    </InterviewScriptBody>
  </NotificationWrapper>)
}
const NotificationWrapper = styled.div`
    position: relative;
    padding: 0px;
    width: 100%;
`
const InterviewScriptHeader = styled.div`
    padding: 20px 24px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    h2 {
        color: #101828;
        font-family: Figtree;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 155.556% */
        margin-bottom: 0px;
        span {
            color: #475467;
            font-family: Figtree;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
        }
    }
    .question-btn {
        padding: 10px 16px;
        height: 100%;
        border-radius: 8px;
        border: 1px solid #D0D5DD;
        background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: #344054;
        font-family: Figtree;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
        i {
            margin-right: 5px;
        }
    }

    border-bottom: 1px solid #EAECF0;
`
const InterviewScriptBody= styled.div`
    padding: 20px 24px;
    .react-tagsinput {
        border-radius: 8px;
        border: 1px solid var(--gray-300, #D0D5DD);
        background: var(--base-white, #FFF);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        padding-left: 35px;
        padding: 5px 12px;
        padding-bottom: 0px;
    }
    .react-tagsinput-tag {
        border-radius: 16px;
        border: 1px solid var(--Primary-200, #CAEBF9);
        background: var(--Primary-50, #E7F7FF);
        mix-blend-mode: multiply;
        color: var(--Primary-700, #007AAB);
        text-align: center;
        /* Text xs/Medium */
        font-family: Figtree;
        font-size: 12px;
        font-style: normal;
        line-height: 18px; /* 150% */
        a {
            text-decoration:none;
        }
    }
    .form-check {
        padding-bottom: 12px;
        label {
            padding-bottom: 8px;
        }
    }
    .form-check-input:checked[type=checkbox] {
        border: 1px solid var(--primary-600, #1693C7);
        background: var(--primary-50, #E7F7FF);
        background-image: url(${check});
        background-size: contain;
      }
`