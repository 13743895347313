import { useState } from 'react';

export default function useToken(){
    const getToken = () => {
        // localStorage.clear();
        const tokenString = localStorage.getItem('token');
        return tokenString
        // return tokenString
    };
    const getAccountId = () => {
        const accountId = localStorage.getItem('account_id');
        return accountId
    };
    const getUserId = () => {
        const user = localStorage.getItem('user_id');
        return user
    };
    const [token, setToken] = useState(getToken());
    const [accountId, setAccountId] = useState(getAccountId());
    const [userId, setUserId] = useState(getUserId());

    const saveToken = (userToken, user) => {
        localStorage.setItem('token', userToken);
        localStorage.setItem('account_id', user.account_id);
        localStorage.setItem('user_id', user.id);
        // setToken(userToken.token);
        setToken(userToken);
        setAccountId(user.account_id);
        setUserId(user.id);
    };

    return {
        setToken: saveToken,
        token,
        accountId,
        userId
    }
}
