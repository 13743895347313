import React, { useState, useEffect } from "react";
import styled from "styled-components";
import chat_green from './../../../src/assets/images/chat_green.png';
import chat_red from './../../../src/assets/images/chat_red.png';

import Sidebar from "./../../components/client/sidebar";
import Header from "./../../components/client/header";
import Content from "./../../components/client/content";

export default function ClientDashboard(props) {
  const [tag, setTag] = useState('Content');
  const themeItems = [
    {name: 'Theme1', description: 'How changes in security architecture affect database design', percent: 37, higher: true, compareLast: 70},
    {name: 'Theme2', description: 'Cost versus scalability considerations', percent: 24, higher: false, compareLast: 32},
    {name: 'Theme3', description: 'Performance heuristics for Postgres databases', percent: 18, higher: true, compareLast: 25},
  ];
  useEffect(() => {
    const keyDownHandler = (event) => {
      console.log('User pressed: ', event.key);

      if (event.key === 'Enter') {
        event.preventDefault();
        if(tag === 'Content') setTag('Themes');
        else setTag('Content');
      }
    }
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  });
  return (<ClientDashboardWrapper className='container-fluid'>
    <div className="row flex-nowrap">
      <div className="col-auto px-0 bg-sidebar">
        <Sidebar page="dashboard"/>
      </div>
      <div className="col body p-0">
        <Header title={'ClientDashboard'} subItem={ {
            'btngroup': [{name: 'Overall'}, {name:'Scripts'}, {name:'Content',active: true}, {name:'Themes'}],
            'subTitle': 'Performance analytics based on prospect behaviors',
          
          } } onPrevMain={(name)=>{setTag(name);}} tag={tag} />
        <BodyWrapper>
          <div className="body-card">
            {tag === 'Themes' && (
              <div className="theme-card">
                {themeItems.map ((item, index)=>(
                  <div className="card-item" key={'theme-' + index}>
                    <div className="card-header-item">
                      <h3>
                        {item.name}: <span>{item.description}</span>
                      </h3>
                    </div>
                    <div className="card-footer">
                      <h2>{item.percent}%</h2>
                      <div className={`card-badge ${item.higher ? 'green':'red'}`}>
                        <i className="fa fa-line-chart" aria-hidden="true"></i>
                        {item.compareLast}% <span>vs last month</span>
                      </div>
                    </div>
                    <img src={item.higher ? chat_green : chat_red} alt='chat_img'/>
                    <div className="card-view">
                      <a>
                        View report
                      </a>
                    </div>
                  </div>
                ))}
              </div>
              
            )}
            {tag === 'Content' && (
              <Content />
            )}
          </div>
        </BodyWrapper>
      </div>
    </div>
  </ClientDashboardWrapper>)
}

const ClientDashboardWrapper = styled.div`
.bg-sidebar {
    background-color: #0A5987;
  }
  .col.body {
    background: #F2F4F7;
    overflow: auto;
    position: relative;
    height: 100vh;
  }
`
const BodyWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  .body-card {
    display: flex;
    padding: 30px;
    cursor: pointer;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid #EAECF0;
    background:  #FFF;
    border-radius: 16px;
    margin: 20px;
    .btn-group {
      padding: 10px 0px;
      button.btn {
        color: #1D2939;
        font-family: Figtree;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
        border: 1px solid #D0D5DD;
        &.active, &:hover, &:active {
            background: #F9FAFB;
        }
      }

    }
    h3 {
      color:  #101828;
      font-family: Figtree;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px; /* 155.556% */
    }
  }
  .theme-card {
    display: flex;
    .card-item {
      border-radius: 12px;
      border: 1px solid  #EAECF0;
      background:  #FFF;
      margin-right: 10px;
      width: 333px;
      max-width: 100%;
      .card-header-item {
        padding: 24px;
        padding-bottom: 8px;
      }
      h3 {
        color:  #475467;
        font-family: Figtree;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
        span {
          color: #475467;
          font-weight: 500;
          line-height: 20px;
        }
        margin-bottom: 0px;
      }
    }
    .card-footer {
      background:  #FFF;
      display: flex;
      justify-content: space-between;
      border: 0px;
      padding: 0px 24px;
      padding-bottom: 20px;
    }
    img {
      padding: 0px 24px;
      width: 100%;
      padding-bottom: 24px;
    }
    .card-view {
      display: flex;
      border-top: 1px solid #EAECF0;
      justify-content: flex-end;
      a {
        color: #344054;
        font-family: Figtree;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
        border-radius: 8px;
        border: 1px solid #D0D5DD;
        padding: 8px 14px;
        background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }
      padding: 16px 24px;
    }
    .card-badge{
      display: flex;
      padding: 2px 10px;
      height: 26px;
      align-items: center;

      color: #000;
      i {
          color: #3FABD7;
      }
      
     
      &.green {
        color: #067647;
        i {
          color: #067647;
        }
      }
      &.red {
        color: #B42318;
        i {
          color: #B42318;
        }
      }
      span {
        margin-left: 5px;
        color: #000;
      }
    }
  }
`
