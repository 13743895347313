import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useDispatch, useSelector } from "react-redux";
import {
  getUsersData,
} from "./../action/api";

import Sidebar from "./../components/sidebar";
import Header from "./../components/header";
import GlobalSettings from "./settings/globalsettings";
import RolesPermission from "./settings/rolepermisson";
import Users from "./settings/users";

export default function Settings(props) {
  const state = useSelector((state) => state.apiReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    getUsersData()(dispatch);
  }, [dispatch]);
  const [tag, setTag] = useState('Global settings for all clients');
  const [ search, setSearch] = useState('');
  const [showModal, setShowModal] = useState(false);
    const openModal = () => {
        setShowModal(true);
    };
    const closeModal = () => {
        setShowModal(false);
    };
  const [addCompanyName, setAddCompanyName] = useState('');
  const [addCompanyWebsite, setAddCompanyWebsite] = useState('');
  const [addCompanyEmail, setAddCompanyEmail] = useState('');
  const [settings, setSettings] = useState([
    {company: 'Catalogapp', url:'Catalogapp.io', key_contacts: ['sara@catalogapp.io', 'george@catalogapp.io'], created: '21/09/23 04:11:05', interviews: 9 },
    {company: 'Capsule', url:'getcapsule.com', key_contacts: ['johndoe@cmdr.ai', 'george@cmdr.ai'], created: '21/09/23 04:11:05', interviews: 34 },
    {company: 'Command+R', url:'cmdr.ai', key_contacts: ['john@getcapsule.com'], created: '21/09/23 04:11:05', interviews: 24 },
    {company: 'Hourglass', url:'hourglass.app', key_contacts: ['mathias@hourglass.app','wyatt@hourglass.app'], created: '21/09/23 04:11:05', interviews: 5 },
    {company: 'Layers', url:'getlayers.io', key_contacts: ['johndoe@getlayers.io','sara@getlayers.io','george@getlayers.io'], created: '21/09/23 04:11:05', interviews: 12 },
    {company: 'Quotient', url:'quotient.co', key_contacts: ['johndoe@quotient.co','george@quotient.co'], created: '21/09/23 04:11:05', interviews: 132 },
    {company: 'Sisphus', url:'sisyphus.com', key_contacts: ['ivan@sisyphus.com','natasha@sisyphus.com','leah@sisyphus.com'], created: '21/09/23 04:11:05', interviews: 76 },
    {company: 'Global Bank', url:'global-bank.co', key_contacts: ['jane@global-bank.co','maria@global-bank.co'], created: '21/09/23 04:11:05', interviews: 98 },
    {company: 'Spherule', url:'spherrule.com', key_contacts: ['johndoe@spherule.com','sara@spherule.com','george@spherule.com'], created: '21/09/23 04:11:05', interviews: 178 },
  ])
  return tag !== 'Users' ? (<SettingsWrapper className='container-fluid'>
    <div className="row flex-nowrap">
      <div className="col-auto px-0 bg-sidebar">
        <Sidebar page="settings"/>
      </div>
      <div className="col body p-0">
        <Header title={'Settings'} subItem={ {
            'btngroup': [{name: 'Global settings for all clients',active: true}, {name:'Roles & Permission'}, {name:'Users'}],
            'subTitle': '',
          
          } } onPrevMain={(name)=>{setTag(name);}} tag={tag} />
        <BodyWrapper>
            {tag == 'Global settings for all clients' && <GlobalSettings />}
            {tag == 'Roles & Permission' && <RolesPermission />}
        </BodyWrapper>
      </div>
    </div>
  </SettingsWrapper>) : (<Users updateTag={(name)=>{setTag(name)}}/>)
}
const SettingsWrapper = styled.div`
.bg-sidebar {
    background-color: #0A5987;
  }
  .col.body {
    background: #F2F4F7;
    overflow: auto;
    position: relative;
    height: 100vh;
  }
`
const BodyWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  .body-card {
    display: flex;
    cursor: pointer;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    background:  #FFF;
    border-radius: 16px;
    margin: 20px;
    table {
      border-collapse: collapse;
      border-radius: 1em;
      overflow: hidden;
      width: 100%;
      padding: 12px;
      color: var(--gray-600, #475467);
      font-family: Figtree;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 150% */
      thead {
        background: #F9FAFB;
        tr{
          th{
            padding: 12px 24px;
          }
        } 
      }
      tbody {
        tr {
          border-bottom: 1px solid var(--gray-200, #EAECF0);
          td {
            padding: 16px 24px;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: #475467;
            h3 {
              color: var(--gray-900, #101828);
              font-family: Figtree;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; 
              margin: 0px;
            } 
            p {
              color: var(--gray-600, #475467);
              font-family: Figtree;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
            }
            .badge {
              border: 1px solid #CAEBF9;
              padding: 2px 10px;
              background: #E7F7FF;
              border-radius: 16px;
              color: #007AAB;
              text-align: center;
              font-family: Figtree;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px; /* 142.857% */
              margin-right: 5px;
              margin-bottom: 2px;
            }
            
          }
          .account-action{
            display: flex;
          }
        }
      }
      margin-bottom: 0px;
    }
  }
  .filter-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 18px 20px;
    .filter-btn {
      padding: 10px 16px;
    border-radius: 8px;
    border: 1px solid var(--gray-300, #D0D5DD);
      background: var(--base-white, #FFF);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      img {
        width: 20px;
        margin-right: 8px;
      }
    }
    color: var(--gray-700, #344054);
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    input {
      border-radius: 8px;
      padding: 10px 14px;
      border: 1px solid var(--gray-300, #D0D5DD);
      background: var(--base-white, #FFF);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
  }
  .table-pagination {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 12px 24px;
    .filter-btn {
      padding: 8px 14px;
      border-radius: 8px;
      border: 1px solid var(--gray-300, #D0D5DD);
        background: var(--base-white, #FFF);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      img {
        width: 20px;
      }
      color: var(--gray-700, #344054);
      font-family: Figtree;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
     }
    }
    ul.pagination {
      margin-bottom: 0px;
      li.page-item a {
        border: 0px;
        color: var(--gray-800, #1D2939);
        text-align: center;
        font-family: Figtree;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }
      li.page-item.active a {
        border-radius: 8px;
        background: var(--gray-50, #F9FAFB);
      }
    }
  }
  .modal.fade {
    background:none;
    form .form-group {
        label {
            color:  #344054;
            font-family: Figtree;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
            margin-bottom:  6px;
        }
        input, textarea {
            border-radius: 8px;
            border: 1px solid #D0D5DD;
            background: #FFF;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            color: #667085;
            font-family: Figtree;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */  
            margin-bottom: 16px;
        }
    }
    .modal-footer {
      display: block;
      h3 {
        color: var(--gray-900, #101828);
        font-family: Figtree;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
      }
      p {
        color: var(--gray-600, #475467);
        font-family: Figtree;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
      .modal-footer-btn {
        display: flex;
        justify-content: flex-end;

      }
    }
    .button-copy {
      background: #1693C7;
      padding: 10px 16px;
      border-radius: 8px;
      color: white;
      cursor: pointer;
      display: inline-block;
      font-family: Figtree;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      i  {
          margin-right: 8px; 
      }
      &.email {
          background: white;
          color: #344054;
          border: 1px solid #D0D5DD;
          margin-right: 10px;
      }
  }
  }
}
`