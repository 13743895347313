import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Dashboard from './pages/dashboard';
import ClientDashboard from './pages/client/dashboard';
import Accounts from './pages/accounts';
import Settings from './pages/settings';
import EditUser from './pages/settings/editUser';
import Login from './pages/auth/login';
import ForgotPassword from './pages/auth/forgot-password';
import InterviewSession from './pages/client/interview-session/interview-session';
import ClientSettings from './pages/client/settings/client-settings';
import InterviewScript from './pages/client/interview-script/interview-script';
import FitnessCriteria from './pages/client/fitness-criteria/fitness-criteria';
import useToken from './pages/auth/useToken';
import Users from './pages/client/users/users';

function App() {
  const {token, setToken} = useToken();
  if (!token) {
    return <Login/>
  }
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/accounts" element={<Accounts />} />
          <Route path="/users" element={<Users />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/settings/user/:id" element={<EditUser />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />

          <Route path="/client" element={<ClientDashboard />} />
          <Route path="/interview-session" element={<InterviewSession />} />
          <Route path="/client-settings" element={<ClientSettings />} />
          <Route path="/interview-script" element={<InterviewScript />} />
          <Route path="/fitness-criteria" element={<FitnessCriteria />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;