import React, { useState, useEffect } from "react";
import styled from "styled-components";
import loginLogo from './../../assets/images/logo1.svg';
import { useDispatch, useSelector } from "react-redux";
// import { useLocation } from 'react-router-dom';

import Button from "../../components/basics/button";
import { getTokenData, getUsersData } from "../../action/api";
import useToken from "./useToken";

export default function Login(props) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('');
  const [flag, setFlag] = useState(-1);
  const dispatch = useDispatch();
  const {token, setToken} = useToken();

  // const location = useLocation();
  // const { hash, pathname, search } = location;
  const [users, setUsers] = useState([]);
  const [errText, setErrText] = useState('');
  useEffect(() => {
    getUsersData(0, 999)(dispatch).then((res) => {
      if (res && res.objects)
        setUsers([...res.objects])
    });
  }, [dispatch]);
  useEffect(() => {
    if (flag === 0 ) {
      if (password.length < 8 || !validateEmail(email))
        setFlag(1);
    }
  }, [flag])
  function submitFunc() {
    if (password.length >= 8 && validateEmail(email)) {
      getTokenData(email, password)(dispatch).then((res)=> {
        if (res && res.token) {
          getUsersData(0, 1000)(dispatch).then((res1) => {
            if (res1 && res1.objects) {
              const user = res1.objects.find(obj => obj.email === email);
              setToken(res.token, user);
              window.location.href = '/';
            }
          });
          // if (pathname == '/login')
          // else
          //   window.location.href=pathname;
        } else {
          if (res.status !== 200) {
            setErrText(res.statusText);
            setFlag(1);
          }

          return;
        }
      });
    }
  }
  const validateEmail = (email) => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (<LoginWrapper>
    <div className="modal fade show" tabIndex={-1} role="dialog" style={{ display: 'block' }}>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <form>
          <div className="modal-content">
            <div className="modal-header">
              <img src={loginLogo} />
              <h2>Welcome back</h2>
              <h5 className="modal-title">Please enter your detail</h5>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor={"modal_title"}>Email</label>
                <input type="email" className={`form-control ${flag === 1 && !validateEmail(email) && 'form-validation'}`} id="modal_title" aria-describedby="modal_title_input" placeholder="Enter email" value={email} onChange={(e)=> {setEmail(e.target.value)}} />
                {flag === 1 && !validateEmail(email)  && <p className="reuiqed-html">This field required and email validate.</p> }
              </div>
              <div className="form-group">
                <label htmlFor={"modal_title"}>Password</label>
                <input type="password" className={`form-control ${flag === 1 && password.length < 8  && 'form-validation'}`} id="modal_title" aria-describedby="modal_title_input" placeholder="Enter password" value={password} onChange={(e)=> {setPassword(e.target.value)}} />
                {flag === 1 && password.length < 8  && <p className="reuiqed-html">This field required and over 8 letters.</p> }
                {flag === 1 && errText !== ''  && <p className="reuiqed-html">{errText}</p> }
              </div>
            </div>
            <div className="modal-footer">
              <div className='modal-footer-check'>
                <div>
                  <input type="checkbox" />&nbsp;&nbsp;Remember password
                </div>
                <a href="">Forgot password</a>
              </div>

              <div className="modal-footer-btn">
                {/* <Button title="Cancel" outline onClickBtn={closeModal} /> */}
                <Button title={'Login'} onClickBtn={()=>{
                  setFlag(0);
                  submitFunc();
                }} />
              </div>
            </div>
          </div>
        </form> 
      </div>
    </div>
  </LoginWrapper>)
}

const LoginWrapper = styled.div`
  width: 100%;
  .modal.fade {
    background: var(--primary-800, #0A5987);
    padding: 32px 40px;
    .modal-dialog, form {
      width: 100%;
    }
    form .form-control.form-validation {
      border-color: red;
      margin-bottom: 3px;
    }
    form .form-group {
      label {
        color:  #344054;
        font-family: Figtree;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        margin-bottom:  6px;
      }
      input, textarea {
        border-radius: 8px;
        border: 1px solid #D0D5DD;
        background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: #667085;
        font-family: Figtree;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */  
        margin-bottom: 16px;
      }
    }
    .modal-footer {
      display: block;
      border: 0px;
      padding: 32px 40px;
      padding-top: 0px;
      h3 {
        color: var(--gray-900, #101828);
        font-family: Figtree;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
      }
      p {
        color: var(--gray-600, #475467);
        font-family: Figtree;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
      .modal-footer-check {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
        a {
          color:  #007AAB;
          font-family: Figtree;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          text-decoration: none;
          line-height: 20px; /* 142.857% */
        }
      }
      .modal-footer-btn {
        display: flex;
        justify-content: flex-end;
        & >div {
          width: 100%;
          text-align: center;
        }
      }
    }
    .button-copy {
      background: #1693C7;
      padding: 10px 16px;
      border-radius: 8px;
      color: white;
      cursor: pointer;
      display: inline-block;
      font-family: Figtree;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      i {
        margin-right: 8px; 
      }
      &.email {
        background: white;
        color: #344054;
        border: 1px solid #D0D5DD;
        margin-right: 10px;
      }
    }
  }
  p.reuiqed-html {
    color: red!important;
    font-size: 12px;
    margin-bottom: 0px;
  }
  .modal-header {
    border: 0px;
    display: flex;
    flex-direction: column;
    padding: 32px 40px;
    padding-bottom: 0px;
    img {
      display: flex;
      align-items: flex-start;
      margin-bottom: 24px;
      height: 70px;
      margin-left: 70px;
    }
    h2 {
      color: var(--gray-900, #101828);
      text-align: center;
      font-family: Figtree;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 133.333% */
      margin-bottom: 8px;
    }
    h5 {
      color: var(--gray-600, #475467);
      text-align: center;
      font-family: Figtree;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      margin-bottom: 0px;
    }
  }
  .modal-body {
    padding: 24px 40px;
    padding-top: 0px;
  }
`
