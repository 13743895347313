import React, { useState, useEffect } from "react";
import styled from "styled-components";

export default function SubPage(props) {
    const { item, main } = props;
    const [showModal, setShowModal] = useState(false);
    const openModal = () => {
        setShowModal(true);
    };
    const user_session_details = [
        {name: 'CRM Link', value: ''},
        {name: 'Browser', value: ''},
        {name: 'IP', value: ''},
        {name: 'Device', value: ''},
        {name: 'Session', value: ''},
        {name: 'Conversation Actived', value: ''},
      ]
    const closeModal = () => {
        setShowModal(false);
    };
    useEffect(() => {
        // const keyDownHandler = (event) => {
        //   console.log('User pressed1: ', event.key);
        //   setShowModal(true); 
    
        //   if (event.key === 'Enter') {
        //     event.preventDefault();
        // }
        // }
        // document.addEventListener('keydown', keyDownHandler);
        // return () => {
        //   document.removeEventListener('keydown', keyDownHandler);
        // };
      });
  return (<SubPageWrapper>
    <SubPageHeader>
        <div className="subpage-header-left">
            <h3>{main.prospect_email_address}</h3>
            <div className="badge">
                <i className="fa fa-certificate" aria-hidden="true"></i>
                Prospect Fitness Score:
                <span>{main.value} / 10</span>
            </div>
        </div>
        <div className="subpage-header-right">
            <p>{item.most_recent_session}</p>
            <div className="badge">
                <i className="fa fa-clock-o" aria-hidden="true"></i>
                Duration:
                <span>{item.duration}</span>
            </div>
        </div>
    </SubPageHeader>
    <SubPageBody>
        <h4>Interview Transcript</h4>
        {item.transcript && item.transcript.map((transcript, index) => (
            <div key={"transcript-" + index}>
                <div key={'transcript-' + index} className={`transcript-item`} >
                    <h2 className={`orange`}> {'ACME'}<span>{transcript.questioned_at}</span></h2>
                    <p>{transcript.question_text || 'What prompted you to download the data ecosystem whitepaper?'}</p>
                </div>
                <div key={'transcript-' + index} className={`transcript-item`} >
                    <h2>{'PROPSPECT'} <span>{transcript.answered_at}</span></h2>
                    <p>{transcript.answer_text}</p>
                </div>
            </div>
        ))}
        <div className="micro-interview">
            <div className="micro-line"></div>
            <div className="micro-badge">
                <span>{item.end_at}</span> micro interview fully completed
            </div>
            <div className="micro-line"></div>

        </div>
        <div className="user-session-details">
            <h3>User Session Details</h3>
            <div className="session-items">
                {user_session_details && user_session_details.map((sessionItem, index) => (
                    <div key={'sessionItem-' + index}  className="session-item">
                        <h2>{sessionItem.name}: <span>{sessionItem.value}</span></h2>
                    </div>
                ))}
            </div>
        </div>
    </SubPageBody>
    <SubPageFooter>
            {/* <h4>{item.account_name}</h4>
            <p>{item.summary}</p> */}
            <div className="button-group">
                {/* <button className="button-copy email"  data-toggle="modal" data-target="#exampleModal"  onClick={openModal}>
                    <i className="fa fa-envelope-o" aria-hidden="true"></i>
                    Draft Email
                </button> */}
                <h2>Draft response to prospect</h2>
                <p>
                    {item.summary}
                </p>
                <div className="button-copy">
                    <i className="fa fa-files-o" aria-hidden="true"></i>
                    Copy to clipboard
                </div>
            </div>
            {showModal && (
                <div className="modal fade show" tabIndex={-1} role="dialog" style={{ display: 'block' }}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLongTitle">New Email</h5>
                                    {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closeModal}> 
                                        <span aria-hidden="true">&times;</span>
                                    </button> */}
                                </div>
                                <div className="modal-body">
                                    <form>
                                        <div className="form-group">
                                            <label htmlFor={"modal_title"}>Title</label>
                                            <input type="email" className="form-control" id="modal_title" aria-describedby="modal_title_input" placeholder="" value={main.prospect}/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="modal_to">Password</label>
                                            <input type="email" className="form-control" id="modal_to" placeholder="" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="modal_body">Body</label>
                                            <textarea className="form-control" id="modal_body" rows={3}>
                                            We understand you're planning to implement Postgres and are also interested in our database design services. Our world-class implementation team offers a variety of services including...
                                            </textarea>
                                        </div>
                                    </form> 
                                </div>
                                <div className="modal-footer">
                                    <div className="button-copy email" data-dismiss="modal"  onClick={closeModal}>Cancel</div>
                                    <div className="button-copy">Send</div>
                                </div>
                            </div>
                </div>
                </div>
            )}
            {showModal && <div className="modal-backdrop show" onClick={closeModal}></div>}
        </SubPageFooter>
  </SubPageWrapper>)
}

const SubPageWrapper = styled.div`
    max-width: 668px;
    margin-left: 52px;
    margin-top: 20px;
    background: #fff;
    border-radius: 12px;
    border-width: 1px;
    border-style: solid;
    border-color: #EAECF0;
    gap: 4px;
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 40px);
    .badge{
        display: flex;
        padding: 2px 10px 2px 8px;
        height: 24px;
        align-items: center;
        gap: 4px;
        border-radius: 16px;
        mix-blend-mode: multiply;
        background: #E7F7FF;
        border: 1px solid #CAEBF9;
        color: #000;
        i {
            color: #3FABD7;
        }
        span {
            color: #0b5987;
        }
    }
`
const SubPageHeader= styled.div`
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    .subpage-header-left {
        h3 {
            font-family: Figtree;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            color: #101828;
            margin-bottom: 4px;
         
        }
    }
    .subpage-header-right {
        p {
            font-family: Figtree;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: #667085;
            margin-bottom: 4px;
            
        }
        .badge {
            border: 1px solid #EAECF0;
            background: #F9FAFB;
            i {
                color: #667085;
            }
            span {
                color: #344054;
            }
        }
    }
    border-bottom: 1px solid #EAECF0;

`
const SubPageBody = styled.div`
    padding: 16px 24px;
    h4 {
        font-family: Figtree;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
    }
    .transcript-item {
        margin-bottom: 4px;
        h2 {
            font-family: Figtree;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            color: #3FABD7;
            &.orange {
                color:#EF6820;
            }
            span {
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0em;
                color: #667085;
                margin-left: 12px;
            }
            margin-bottom: 0px;
        }
        p {
            font-family: Figtree;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            color: black;  
            margin: 0px;          
        }
    }
    .micro-interview {
        margin: 20px 0px;
        display: flex;
        justify-content: space-evenly;
        .micro-line {
            width: auto;
            min-width: 105px;
            border: 0.5px solid #D0D5DD;
            border-bottom: 0px;
            height: 0px;    
            margin-top:auto;
            margin-bottom: auto;
        }
        .micro-badge {
            border: 1px solid #EAECF0;
            padding: 2px 8px;
            background: #F9FAFB;
            font-family: Figtree;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: center;
            color: #344054;
            span {
                font-weight: 700;
            }
            border-radius: 12px;
            gap: 4px;
        }
    }
    .user-session-details {
        h3 {
            font-family: Figtree;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            margin-bottom: 8px;
        }
        .session-items {
            display: flex;
            flex-wrap: wrap;
        }
        .session-item {
            padding: 1px 8px;
            border-radius: 4px;
            background: #F2F4F7;
            margin: 4px;
            h2 {
                font-family: Figtree;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0em;
                color: #667085;
                span {
                    font-family: Figtree;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0em;
                    color: #101828;
                    margin-left: 8px;
                }
                margin: 0px;
            }
        }
    }
`
const SubPageFooter = styled.div`
    border-top: 1px solid #EAECF0;
    padding: 16px 24px;
    .button-group h2 {
        color: var(--Gray-900, #101828);
        font-family: Figtree;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
    }
    .button-group p {
        color: var(--Gray-950, #0C111D);
        font-family: Figtree;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 0px;
        line-height: 20px; /* 142.857% */
    }
    h4 {
        font-family: Figtree;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #101828;
        padding-bottom: 16px;
        margin: 0px;
    } 
    p {
        font-family: Figtree;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        color: #0C111D;
        padding-bottom: 16px;
        margin: 0px;
    }
    .button-copy {
        background: #1693C7;
        padding: 10px 16px;
        border-radius: 8px;
        color: white;
        cursor: pointer;
        display: inline-block;
        font-family: Figtree;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        i  {
            margin-right: 8px; 
        }
        &.email {
            background: white;
            color: #344054;
            border: 1px solid #D0D5DD;
            margin-right: 10px;
        }
    }
    .modal.fade {
        background: rgb(160,161,165);
        background-filter: blur(8px);
        form .form-group {
            label {
                color:  #344054;
                font-family: Figtree;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px; /* 142.857% */
                margin-bottom:  0px;
            }
            input, textarea {
                border-radius: 8px;
                border: 1px solid #D0D5DD;
                background: #FFF;
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                color: #667085;
                font-family: Figtree;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 150% */  
            }
        }
    }
`