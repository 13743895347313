import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import Sidebar from "../../../components/client/sidebar";
import Header from "../../../components/client/header";
import SubPage from "./sub-page";
import { getInterviewSessionsData, getInterviewSessionData } from "../../../action/api";

export default function InterviewSession(props) {
  const state = useSelector((state) => state.apiInterviewScriptsReducer);
  const [currentPage, setCurrentPage] = useState(state.interview_script_page || 1);
  const [totalPage, setTotalPage] = useState(state.interview_script_total);
  const dispatch = useDispatch();
  useEffect(() => {
    getSessions(state.interview_script_page, state.interview_script_limit)
  }, [dispatch]);
  const getSessions = (pages, rows) => {
    getInterviewSessionsData(pages, rows, true)(dispatch).then((res) => {
      if (res)
        setSessions([...res])
      if (res)
        setTotalPage(res.length)
    });
  }
  const [subPage, setSubPage] = useState(-1);
  const [sessions, setSessions] = useState([]);
  const [subSession, setSubSession] = useState([]);

  useEffect(() => {
    const keyDownHandler = (event) => {
      console.log('User pressed: ', event.key);

      if (event.key === 'Enter') {
        event.preventDefault();
        setSubPage(0);
      }
    }
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  });
  async function SubPageFunc(item, flag) {
    await getInterviewSessionData(item.parent_id, item.id)(dispatch).then(res=> {
      if (res && res.data && res.data.objects)
        setSubSession(res.data.objects);
      else 
        setSubSession([])
      setSubPage(flag);
    })
  }
  return (<InterviewSessionWrapper className='container-fluid'>
    <div className="row flex-nowrap">
      <div className="col-auto px-0 bg-sidebar">
        <Sidebar page="interview-session"/>
      </div>
      <div className="col body p-0">
        <Header title={'Interview Sessions'} subItem={subPage >= 0 ? sessions[subPage] : null } onPrevMain={()=>{
          setSubPage(-1);
        }}/>
        <BodyWrapper>
          {subPage == -1 ? sessions.map((item, index) => (
            <div className="card" key={index} onClick={()=> {
              SubPageFunc(item, index);
            }}>
              <div className="card-header">
                <h4>{item.account_name}</h4>
                <div className={`card-badge ${item.color}`}>
                  <i className="fa fa-certificate" aria-hidden="true"></i>
                  <span>{item.fitness_score} / 10</span>
                </div>
              </div>
              <div className="card-row">
                  <h4>Most Recent</h4>
                  <p>{item.most_recent}</p>
              </div>
              <div className="card-row">
                  <h4>Prospect</h4>
                  <p>{item.prospect_company_name}</p>
              </div>
              <div className="card-summary">
                  <h4>Summary</h4>
                  <p>{item.summary}</p>
              </div>
            </div>
          )) : (
            <SubPage item={subSession ? subSession[0]:{}} main={subSession ? subSession[0]:{}} />
          )}
          {sessions.length == 0 &&  (<div className="card">
              <div className="card-row">
              <h4>There is no session</h4>
              </div>
              </div>)}
        </BodyWrapper>
      </div>
    </div>
  </InterviewSessionWrapper>)
}

const InterviewSessionWrapper = styled.div`
  .bg-sidebar {
    background-color: #0A5987;
  }
  .col.body {
    background: #F2F4F7;
    height: 100%;
    max-height: 100vh;
    overflow: auto;
    position: relative;
  }
`
const BodyWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  .card {
    display: flex;
    padding: 24px;
    width: 352px;
    cursor: pointer;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid #EAECF0;
    background:  #FFF;
    border-radius: 16px;
    margin: 20px;
    .card-header {
      display: flex;
      width: 100%;
      background: #fff;
      border: 0px;
      justify-content: space-between;
      padding: 0px;
      padding-bottom: 8px;
      h4 {
        color: #101828;
        font-family: Figtree;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px; /* 150% */
      }
      .card-badge {
        display: flex;
        padding: 2px 10px 2px 8px;
        height: 24px;
        align-items: center;
        gap: 4px;
        border-radius: 16px;
        mix-blend-mode: multiply;
        &.green {
          border: 1px solid #ABEFC6;
          background: #ECFDF3;
          color: #067647;
        }
        &.red {
          border: 1px solid #FECDCA;
          background: #FEF3F2;
          color: #B42318;
        }
        &.yellow {
          border: 1px solid  #FEDF89;
          background: #FFFAEB;
          color: #B54708;
        }
        text-align: center;
        font-family: Figtree;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
      }
    }
    .card-row {
      display: flex;
      margin-bottom: 2px;
    }
    .card-row, .card-summary {
      h4 {
        color: #101828;
        font-family: Figtree;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
        margin: 0px;
        margin-right: 4px;
      }
      p {
        color: #667085;
        font-family: Figtree;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin: 0px;
      }
    }
  }
`
