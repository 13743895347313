import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Sidebar from "../../../components/client/sidebar";
import Header from "../../../components/client/header";
import Design from "./design";
import Questions from "./question";
import Notification from "./notification";
import Options from "./options";
import { useDispatch, useSelector } from "react-redux";
import { getInterviewScriptsData } from "../../../action/api";

export default function SubInterviewScript(props) {
  const {onPrevSubInterviewScript ,item, design } =props;
  const state = useSelector((state) => state.apiReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    getInterviewScriptsData()(dispatch);
  }, [dispatch]);
  const [tag, setTag] = useState('Design');
  const [flag, setFlag] = useState('-1');
  const themeItems = [
    {name: 'Theme1', description: 'How changes in security architecture affect database design', percent: 37, higher: true, compareLast: 70},
    {name: 'Theme2', description: 'Cost versus scalability considerations', percent: 24, higher: false, compareLast: 32},
    {name: 'Theme3', description: 'Performance heuristics for Postgres databases', percent: 18, higher: true, compareLast: 25},
  ];
  const saveScript = () => {
    setFlag('1');
  }
  return (<InterviewScriptWrapper className='container-fluid'>
    <div className="row flex-nowrap">
      <div className="col-auto  px-0 bg-sidebar">
        <Sidebar page="interview-script"/>
      </div>
      <div className="col body p-0">
        <Header title={item.name} preview={true} subItem={ {
            'btngroup': [{name: 'Design', active: true}, {name:'Questions'}, {name:'Notification'}, {name:'Options'}],
            'subTitle': 'Manage your interview script here.'
          } } onPrevMain={(name)=>{
            if(name)
             setTag(name);
            else
              onPrevSubInterviewScript();
            }} tag={tag} />
        <BodyWrapper>
          <div className="body-card">
            {tag == 'Design' ? <Design item={item} flag={flag} design={design} flagFunc={()=> {setFlag('-1')}} /> : tag == 'Notification' ? <Notification /> : tag == 'Options' ? <Options item={item} flag={flag} flagFunc={()=> {setFlag('-1')}}/> :<Questions  item={item} flag={flag} flagFunc={()=> {setFlag('-1')}}/>}
          </div>
        </BodyWrapper>
        <FooterWrapper>
          <div>
            <div className="button-copy email" data-dismiss="modal" onClick={ ()=>{ onPrevSubInterviewScript(); } }>Cancel</div>
            <div className="button-copy" onClick={()=> {
              if (tag == 'Options') {
                setFlag('3');
              } else if (tag == 'Design') {
                saveScript();
              }
              else 
                setFlag('2');
              }}>
              <i className="fa fa-floppy-o" aria-hidden="true"></i>&nbsp;
              Save changes
            </div>
          </div>
        </FooterWrapper>
      </div>
    </div>
    <div role="alert" aria-live="assertive" aria-atomic="true" className="toast" data-autohide="false">
      <div className="toast-header">
        {/* <img src="..." className="rounded mr-2" alt="..."> */}
        <strong className="mr-auto">Bootstrap</strong>
        <small>11 mins ago</small>
        <button type="button" className="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="toast-body">
        Hello, world! This is a toast message.
      </div>
    </div>
  </InterviewScriptWrapper>)
}

const InterviewScriptWrapper = styled.div`
  .bg-sidebar {
    background-color: #0A5987;
  }
  .col.body {
    background: #F2F4F7;
    overflow: auto;
    position: relative;
    height: 100vh;
  }
`
const BodyWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 65px;
  .body-card {
    display: flex;
    cursor: pointer;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid #EAECF0;
    background:  #FFF;
    border-radius: 16px;
    margin: 20px;
    .btn-group {
      padding: 10px 0px;
      button.btn {
        color: #1D2939;
        font-family: Figtree;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
        border: 1px solid #D0D5DD;
        &.active, &:hover, &:active {
          background: #F9FAFB;
        }
      }

    }
    h3 {
      color:  #101828;
      font-family: Figtree;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px; /* 155.556% */
    }
  }
  .theme-card {
    display: flex;
    .card-item {
      border-radius: 12px;
      border: 1px solid  #EAECF0;
      background:  #FFF;
      margin-right: 10px;
      width: 333px;
      max-width: 100%;
      .card-header-item {
        padding: 24px;
      }
      h3 {
        color:  #475467;
        font-family: Figtree;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
        span {
          color: #475467;
          font-weight: 500;
          line-height: 20px;
        }
        margin-top: 16px;
        margin-bottom: 25px;
      }
    }
    .card-footer {
      background:  #FFF;
      display: flex;
      justify-content: space-between;
      border: 0px;
      padding-bottom: 23px;
    }
    .card-view {
      display: flex;
      border-top: 1px solid #EAECF0;
      justify-content: flex-end;
      a {
        color:  #007AAB;
        font-family: Figtree;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
      }
      padding: 16px 24px;
    }
    .card-badge{
      display: flex;
      padding: 2px 10px;
      height: 26px;
      align-items: center;
      gap: 4px;
      border-radius: 16px;
      mix-blend-mode: multiply;
      background: #E7F7FF;
      border: 1px solid #CAEBF9;
      color: #000;
      i {
          color: #3FABD7;f
      }
      span {
          color: #0b5987;
      }
     
      &.green {
        border: 1px solid #ABEFC6;
        background: #ECFDF3;
        color: #067647;
        i {
          color: #067647;
        }
      }
      &.red {
        border: 1px solid #FECDCA;
        background: #FEF3F2;
        color: #B42318;
        i {
          color: #B42318;
        }
      }
    }
  }
`
const FooterWrapper  = styled.div`
  position: fixed;
  width: calc(100% - 260px);
  background: white;
  bottom: 0px;
  padding: 13px 24px;
  display: flex;
  justify-content: flex-end;
  .button-copy {
    margin-left: auto;
    background: #1693C7;
    padding: 10px 16px;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    display: inline-block;
    font-family: Figtree;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    &.email {
      background: white;
      color: #344054;
      border: 1px solid #D0D5DD;
      margin-right: 5px;
    }
  }
`
