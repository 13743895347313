import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import pen1 from './../../../assets/images/pen1.png';
import trash from './../../../assets/images/trash-02.png';
import Header from "../../../components/header";
import Sidebar from "../../../components/client/sidebar";
import Button from "../../../components/basics/button";
import Pagination from "../../../components/basics/pagination";
import arrow_up from '../../../assets/images/arrow-up.png';
import arrow_down from '../../../assets/images/arrow-down.png';
import { addCriteriaData, addFitnessCriteriaData, deleteCriteriaData, deleteFitnessCriteriaData, getFitnessCriteriaData, getFitnessCriteriaListData, updateCriteriaData, updateFitnessCriteriaData } from "../../../action/api";
import CancelModal from "../../../components/modals/cancel";
import FitnessCriteriaModal from "../../../components/modals/fitnesslist";
import SaveToast from "../../../components/toast/save";
import { getErrorMessage } from "../../../config/common";

const offline = false;

export default function FitnessCriteria(props) {
  const state = useSelector((state) => state.apiFitnessReducer);
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState({});
  const [newItem, setNewItem] = useState({});
  const [fitnessFlag, setFitnessFlag] = useState(false);
  const [errFlag, setErrorFlag] = useState('');
  const [currentPage, setCurrentPage] = useState(state.fitness_page);
  const [totalPage, setTotalPage] = useState(state.fitness_total);
  const [toast, setToast] = useState(-1);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    getFitnessCriteriaList(state.fitness_page, state.fitness_limit);
  }, [dispatch]);
  const [delModal, setDelModal] = useState('');
  const [search, setSearch] = useState('');
  const [showModal, setShowModal] = useState(-2);

  const toLocalDate = (utcDateString) => {
    const dt = new Date(`${utcDateString} GMT`);
    const padL = (nr, len = 2, chr = '0') => `${nr}`.padStart(2, chr);
    return dt.getHours() < 12 ? `${padL(dt.getMonth() + 1)}/${padL(dt.getDate())}/${dt.getFullYear()} ${padL(dt.getHours())}:${padL(dt.getMinutes())}:${padL(dt.getSeconds())} AM` : `${padL(dt.getMonth() + 1)}/${padL(dt.getDate())}/${dt.getFullYear()} ${padL(dt.getHours() - 12)}:${padL(dt.getMinutes())}:${padL(dt.getSeconds())} PM`;
  }
  //<undefined | null | "name" | "modified" | "created">
  const [sortMethod, setSortMethod] = useState(null);
  // 1 | -1
  const [sortOrder, setSortOrder] = useState(1);
  const [criteriaList, setCriteriaList] = useState([...state.fitness_critera_list]);
  useEffect(() => {
    if (toast) {
      setTimeout(() => {
        setToast(-1);
      }, 5000);
    }
  }, [toast])
  const getFitnessCriteria = () => {
    if (!!offline)
      return;
    getFitnessCriteriaData(selectedItem.id)(dispatch).then((resSub) => {
      if (resSub.data && resSub.data.objects)
        setSelectedItem({...selectedItem, objects:[...resSub.data.objects]})
      else {
        setSelectedItem({...selectedItem, objects:[]})
      }
      getFitnessCriteriaList(currentPage, state.fitness_limit);
    })
  }
  const getFitnessCriteriaList = (page, limit)  => {
    getFitnessCriteriaListData(page, limit, true)(dispatch).then((res) => {
      if (res && res.objects && res.objects.length > 0 ) {
        // let tempArr  = [...res.objects];
        // tempArr.sort((a, b) => {
        //   const dateA = new Date(a.updated_at);
        //   const dateB = new Date(b.updated_at);
      
        //   // Compare the dates
        //   return dateA - dateB;
        // });
        setCriteriaList([...res.objects]);
      }
      if (res && res.total)
        setTotalPage(res.total)
    })
  }
  const editCreteria = (name, index) => {
    if (!!offline)
      return;
    getFitnessCriteriaData(index)(dispatch).then(res => {
      console.log("editCriteria", res);
      if(res.data.objects)
        setSelectedItem({objects:[...res.data.objects], name: name, id: index })
      else {
        setSelectedItem({objects:[], name: name, id: index })
      }
      setShowModal(-1);

    })
  }
  function closeFitnessModal() {
    setFitnessFlag(false);
  }
  const updateFitnessCriteria = (id, save_button = false) => {
    updateFitnessCriteriaData(id, {name: selectedItem.name})(dispatch).then(res=> {
      console.log('updatefit3->', res);
      if (save_button)
        setNewItem({});
      getFitnessCriteria();
      setToast(1);
    }).catch(err => {
      setToast(2);
    })
  }
  function delFitness(acc) {
    deleteFitnessCriteriaData(acc)(dispatch).then((res) => {
      if ((totalPage - 1) < currentPage * state.fitness_limit)
        getFitnessCriteriaList(currentPage > 1 ? currentPage - 1 : 1, state.fitness_limit);
      else 
        getFitnessCriteriaList(currentPage, state.fitness_limit);
      setDelModal('');
    });
  } 
  function delCriterions(criterion) {
    deleteCriteriaData(selectedItem.id, criterion.id)(dispatch).then((res) => {
      getFitnessCriteria();
      setToast(1);
    }).catch(err => {
      setToast(2);
    })
  }
  async function rankMoveCriterion(criterion, criterionSec) {
    await updateCriteriaData(selectedItem.id, criterion.id, {
      text: criterionSec.text,
      order: criterionSec.order
    })(dispatch).then((res) => {
      setToast(1);

    }).catch(err => {
      setToast(2);
    })
    await updateCriteriaData(selectedItem.id, criterionSec.id, {
      text: criterion.text,
      order: criterion.order
    })(dispatch).then((res) => {
      console.group("--update2->", res)
      if(res && res.data && res.data.objects) {
        getFitnessCriteriaList(currentPage, state.fitness_limit);
        updateFitnessCriteria(selectedItem.id);
        setToast(1);
      }
    }).catch(err => {
      setToast(2);
    })
  }
  function updateCriterion(criterion, text) {
    updateCriteriaData(selectedItem.id, criterion.id, {
      order: criterion.order,
      text: text
    })(dispatch).then((res) => {
      if(res && res.data && res.data.objects) {
        updateFitnessCriteria(selectedItem.id);
      } else if (res.error !== undefined) {
        setErrorMsg(getErrorMessage(res.fields));
        setToast(2);
      }
    })
  }
  return (<FitnessCriteriaWrapper className='container-fluid'>
    <div className="row flex-nowrap">
      <div className="col-auto px-0 bg-sidebar">
        <Sidebar page="fitness-criteria"/>
      </div>
      {showModal == -2 ? <div className="col body p-0">
        <Header title={'Fitness Criteria'}  onPrevMain={(content = 0)=>{
          setErrorFlag('');
          setFitnessFlag(true);
          getFitnessCriteriaList(currentPage, state.fitness_limit);
          // setShowModal(-1);  
        }}  />
        <BodyWrapper>
          <div className="filter-wrapper">
            <Button title="Filters" icon="filter" outline/>
            <input type="text" value={search}  onChange={(e)=>{setSearch(e.target.value);}} placeholder='Search'/>
          </div>
          <div className="body-card">
            <table>
              <thead>
                <tr>
                  <th scope="col" onClick={() => {
                    if (sortMethod === 'name')
                      setSortOrder(-sortOrder)
                    else
                      setSortMethod('name')
                  }}>Name
                  {sortMethod === 'name' && sortOrder === 1 && (<img src={arrow_up} alt="" className="arrow-up-img" />)}
                  {sortMethod === 'name' && sortOrder === -1 && (<img src={arrow_down} alt="" className="arrow-down-img" />)}</th>
                  <th scope="col" onClick={() => {
                    if (sortMethod === 'modified')
                      setSortOrder(-sortOrder)
                    else
                      setSortMethod('modified')
                  }}>Modified
                  {sortMethod === 'modified' && sortOrder === 1 && (<img src={arrow_up} alt="" className="arrow-up-img" />)}
                  {sortMethod === 'modified' && sortOrder === -1 && (<img src={arrow_down} alt="" className="arrow-down-img" />)}</th>
                  <th scope="col" onClick={() => {
                    if (sortMethod === 'created')
                      setSortOrder(-sortOrder)
                    else
                      setSortMethod('created')
                  }}>Created
                  {sortMethod === 'created' && sortOrder === 1 && (<img src={arrow_up} alt="" className="arrow-up-img" />)}
                  {sortMethod === 'created' && sortOrder === -1 && (<img src={arrow_down} alt="" className="arrow-down-img" />)}</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {criteriaList && criteriaList.length > 0 && criteriaList.sort((a, b) => {
                  if (sortMethod === 'name')
                    return (a.name === b.name ? 0 : a.name < b.name ? -1 : 1) * sortOrder;
                  if (sortMethod === 'modified')
                    return (a.updated_at === b.updated_at ? 0 : a.updated_at < b.updated_at ? -1 : 1) * sortOrder;
                  if (sortMethod === 'created')
                    return (a.created_at === b.created_at ? 0 : a.created_at < b.created_at ? -1 : 1) * sortOrder;
                  return 0;
                }).map((criteria, index) => (
                  <tr key={'criteria-' + index}>
                    <td className="criteria-company">
                      <h3>{criteria.name}</h3>
                    </td>
                    <td>{toLocalDate(criteria.updated_at)}</td>
                    <td>{toLocalDate(criteria.created_at)}</td>
                    <td className="criteria-action">
                      <img src={trash} alt="" className="trash-img"  onClick={()=> {setDelModal(criteria.id)}}/>
                      <img src={pen1} alt="pen" onClick={()=> {editCreteria(criteria.name, criteria.id);}}/>
                    </td>
                  </tr>
                ))}
                {criteriaList.length == 0 && <p className="table-empty">
                  Click the “Add Criteria List” button to add fitness criteria
                  </p>}
              </tbody>
            </table>  
            {criteriaList && criteriaList.length > 0 && <div className="table-pagination">
              <Pagination pos={currentPage} total={totalPage} rows={state.fitness_limit} onPageChange={(eVal)=> {
                  setCurrentPage(eVal)
                  getFitnessCriteriaList(eVal, state.fitness_limit)
              }}/>
            </div>}
          </div>
        </BodyWrapper>
      </div> : <div className="col body p-0">
      <Header title={selectedItem.name} subItem={{'title':selectedItem.name, 'subTitle': 'Manage the goals for this interview goal set here.'}} onPrevMain={(v)=>{
        console.log("v", v)
        if(v == -1) 
          setShowModal(-2);
        if(v == 1){
            if(selectedItem && selectedItem.objects && selectedItem.objects.length == 10 ) {
              alert("You can't create criterion more 10 items")
              return;  
            }
            if (newItem.id) {
              console.log("sele->', ", selectedItem)
              addCriteriaData(selectedItem.id, {
                "order": selectedItem.objects && selectedItem.objects.length > 0  ?  selectedItem.objects.length + 1 : 1,
                "text": newItem.text
              })(dispatch).then((res) => {
                if (res.error !== undefined) {
                  setErrorMsg(getErrorMessage(res.fields));
                  setToast(2);
                  setErrorFlag(res);
                }
                else {
                  setErrorFlag('')
                  setToast(1);
                }
                getFitnessCriteria();
              })
            }
            setNewItem({id: selectedItem.objects && selectedItem.objects.length > 0  ?  selectedItem.objects.length : 1, text: ''})
            // addCriteriaData(selectedItem.id, {
            //   "order": selectedItem.objects && selectedItem.objects.length > 0  ?  selectedItem.objects.length : 1,
            //   "text": 'new'
            // })(dispatch).then((res) => {
            //   getFitnessCriteria();
            // })
        }

      }} />
        <BodyWrapper>
          <div className="card criterion">
            <div className="card-header" >
              <h2>Criteria for this Interview</h2>
              <p>Enter & prioritize at least 3 (and up to 10) points of fitness criteria for this prospective customer interview.</p>
            </div>
            <div className="setting-card-body">
              <div className="script-section">
                {selectedItem.objects && selectedItem.objects.length > 0 && (selectedItem.objects.map((criterion, index) =>(
                  <div className="form-group">
                      <label htmlFor={"fitness-crierial"}>#{index + 1}</label>
                      <div className="form-input-group">
                          <input type="text" className="form-control" id="stand_call" aria-describedby="stand_call" value={criterion.text} 
                            onChange={(e)=> {
                              updateCriterion(criterion, e.target.value)
                            }}
                          />
                          <div className="fa-icon">
                              <img onClick={()=>{ delCriterions(criterion) }} src={trash} alt="" className="trash-img" /> &nbsp;&nbsp;
                              {index != 0 && (<img onClick={()=>{ rankMoveCriterion(criterion,selectedItem.objects[index - 1] ) }} src={arrow_up} alt="" className="arrow-up-img" />)}
                              {index != (selectedItem.objects.length - 1) && (<img onClick={()=>{ rankMoveCriterion(criterion,selectedItem.objects[index + 1]) }} src={arrow_down} alt="" className="arrow-down-img" />)}
                          </div>
                      </div>
                  </div>
                )))}
                {newItem.id && (
                  <div className="form-group">
                    <label htmlFor={"fitness-crierial"}>New Criterion <span class="err-flag">{errFlag}</span></label>
                    <div className="form-input-group">
                        <input type="text" className={`form-control ${errFlag ? 'err-flag':''}`} id="stand_call" aria-describedby="stand_call" value={newItem.text} 
                          onChange={(e)=> {
                            const tempVal = e.target.value;
                            setNewItem({id: newItem.id, text: tempVal});
                            // updateCriterion(criterion, e.target.value)
                          }}
                        />
                        {/* <div className="fa-icon">
                            <img onClick={()=>{ delCriterions(criterion) }} src={trash} alt="" className="trash-img" /> &nbsp;&nbsp;
                            {index != 0 && (<img onClick={()=>{ rankMoveCriterion(criterion,selectedItem.objects[index - 1] ) }} src={arrow_up} alt="" className="arrow-up-img" />)}
                            {index != (selectedItem.objects.length - 1) && (<img onClick={()=>{ rankMoveCriterion(criterion,selectedItem.objects[index + 1]) }} src={arrow_down} alt="" className="arrow-down-img" />)}
                        </div> */}
                    </div>
                  </div>
                )
                }
                {(!selectedItem.objects || selectedItem.objects.length == 0) && !newItem.id && (
                  <p> There is no criterion</p>
                )}
              </div>
            </div>
          </div>

          <div className="bottom-buttons outline">
            <Button outline title={"Cancel"} onClickBtn={() => {
              setErrorFlag('');
              setShowModal(-2);
            }} />
            <Button title={"Save changes"} icon="save" onClickBtn={() => {
              // const _promises = selectedItem.objects.map(criterion => {
              //   return new Promise((resolve, reject) => {
              //     updateCriteriaData(selectedItem.id, criterion.id, {
              //       order: criterion.order,
              //       text: criterion.text
              //     })(dispatch).then(resolve).catch(reject)
              //   })
              // })
              // Promise.all(_promises).then(() => {
              //   updateFitnessCriteria(selectedItem.id);
              // })
              if (newItem.id && newItem.text) {
                addCriteriaData(selectedItem.id, {
                  "order": selectedItem.objects && selectedItem.objects.length > 0  ?  selectedItem.objects.length + 1 : 1,
                  "text": newItem.text
                })(dispatch).then((res) => {
                  if (res.error !== undefined) {
                    setErrorMsg(getErrorMessage(res.fields));
                    setToast(2);
                    setErrorFlag(res);
                  }
                  else {
                    setErrorFlag('');
                    setToast(1);
                  }
                  updateFitnessCriteria(selectedItem.id, true);
                });
              } else {
                setToast(2);
                updateFitnessCriteria(selectedItem.id, true);
              }
            }} />
          </div>
        </BodyWrapper>
      </div>}
      
      {fitnessFlag && (
        <FitnessCriteriaModal closeModal={closeFitnessModal} saveModal={(obj)=>{
              addFitnessCriteriaData({
                name: obj.name,
                account_id: localStorage.getItem('account_id')
              })(dispatch).then((res) => {
                  if (res.error !== undefined) {
                    setToast(2);
                    setErrorMsg(getErrorMessage(res));
                  } else {
                    setSelectedItem(obj.name);
                    getFitnessCriteriaList();
                    closeFitnessModal();
                  }
              })
        }} />
      )}
      {fitnessFlag && <div className="modal-backdrop show" onClick={closeFitnessModal}></div>}
    </div>
    {delModal != '' && (
              <CancelModal closeModal={()=>{setDelModal('')}} title="Delete Fitness Criteria List" description="Are you sure you want to delete this list? There is no going back!" saveModal={()=>{
                delFitness(delModal);
            }}/>
          )}
        {delModal != '' && <div className="modal-backdrop show" onClick={()=>{setDelModal('')}}></div>}
        {toast != -1 && (<SaveToast closeToast={()=>{setToast(-1)}} toast={toast} error={errorMsg} />)}
  </FitnessCriteriaWrapper>)
}

const FitnessCriteriaWrapper = styled.div`
  .bg-sidebar {
    background-color: #0A5987;
  }
  .col.body {
    background: #F2F4F7;
    overflow: auto;
    position: relative;
    height: 100vh;
  }
`
const BodyWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  .body-card {
    display: flex;
    cursor: pointer;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    background:  #FFF;
    border-radius: 16px;
    margin: 20px;
    table {
      border-collapse: collapse;
      border-radius: 1em;
      overflow: hidden;
      width: 100%;
      padding: 12px;
      color: var(--gray-600, #475467);
      font-family: Figtree;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 150% */
      thead {
        background: #F9FAFB;
        tr {
          th {
            padding: 12px 24px;
            .arrow-up-img, .arrow-down-img {
              margin-top: -3px;
            }
          }
        }
      }
      tbody {
        tr {
          border-bottom: 1px solid var(--gray-200, #EAECF0);
          td {
            padding: 16px 24px;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: #475467;
            h3 {
              color: var(--gray-900, #101828);
              font-family: Figtree;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; 
              margin: 0px;
            } 
            p {
              color: var(--gray-600, #475467);
              font-family: Figtree;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
            }
          }
          .criteria-action, .criteria-badges {
            display: flex;
          }
        }
      }
      margin-bottom: 0px;
    }
  }
  .filter-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 18px 20px;
    color: var(--gray-700, #344054);
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    input {
      border-radius: 8px;
      padding: 10px 14px;
      border: 1px solid var(--gray-300, #D0D5DD);
      background: var(--base-white, #FFF);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
  }
  .table-pagination {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 12px 24px;
  }
  .bottom-buttons {
    position: fixed;
    width: calc(100% - 275px);
    bottom: 0px;
    right: 0px;
    padding: 13px 24px;
    display: flex;
    justify-content: flex-end;
    &.outline {
      background: white;
      color: #344054;
      border: 1px solid #D0D5DD;
      /* margin-right: 10px; */
    }
  }
  p.table-empty {
    padding: 16px 24px;
    color: rgba(71, 84, 103, 0.50);
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin: 0px;
  }
  .trash-img {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 12px;
  }
  .card.criterion {
    margin-left: 20px;
    margin-top: 24px;
    border-radius: 8px 8px 0px 0px;
    border: 0px;
    background: var(--Base-White, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
    .card-header {
      padding: 20px 24px;
      background: white;
      h2 {
        color: var(--Gray-900, #101828);
        font-family: Figtree;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 155.556% */
      } 
      p {
        color: var(--Gray-600, #475467);
        font-family: Figtree;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        margin: 0px;
        line-height: 20px; /* 142.857% */
      }
    }
    .setting-card-body {
      padding: 20px 24px;
      padding-right: 35px;
      input[type=text].form-control {
        border-radius: 8px;
        border: 1px solid var(--gray-300, #D0D5DD);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: var(--gray-500, #667085);
        font-family: Figtree;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
      }
      .form-group.form-selects {
        width: 200px;
      }
      .form-group .form-input-group {
          display: flex;
          i {
              cursor: pointer;
              color: #667085;
              width: 20px;
              line-height: 36px;
              margin-left: 6px;
          }
      }
      .fa-icon {
        display: flex;
        width: 60px;
        margin-left: 12px;
        img {
          cursor: pointer;
        }
      }
      .form-group label {
          font-family: Figtree;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: left;
          color: #344054;
      }
      .trash-img {
        width: 20px;
        height: 20px;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 12px;
      }
      .arrow-up-img, .arrow-down-img {
        margin-top: auto;
        margin-bottom: auto;
        width: 20px;
        height: 20px;
      }
    }
  }
  .err-flag {
    color: red;
  }
  .form-control.err-flag {
    color: black;
    border-color:red!important;
  }
`
