export const GET_API_ACCOUNT = "GET_API_ACCOUNT";
export const GET_API_ACCOUNTS = "GET_API_ACCOUNTS";
export const POST_API_ACCOUNT = "POST_API_ACCOUNT";
export const PUT_API_ACCOUNT = "PUT_API_ACCOUNT";
export const DELETE_API_ACCOUNT = "DELETE_API_ACCOUNT";

export const GET_API_USER = "GET_API_USER";
export const GET_API_USERS = "GET_API_USERS";
export const POST_API_USER = "POST_API_USER";
export const PUT_API_USER = "PUT_API_USER";
export const DELETE_API_USER = "DELETE_API_USER";

export const GET_API_INTERVIEW_SESSIONS = "GET_API_INTERVIEW_SESSIONS";
export const GET_API_INTERVIEW_SESSION = "GET_API_INTERVIEW_SESSION";
export const DELETE_API_INTERVIEW_SESSION = "DELETE_API_INTERVIEW_SESSION";

export const GET_API_FITNEWSS_CRITERIA_LIST = "GET_API_FITNEWSS_CRITERIA_LIST";
export const GET_API_FITNEWSS_CRITERIA = "GET_API_FITNEWSS_CRITERIA";
export const POST_API_FITNEWSS_CRITERIA = "POST_API_FITNEWSS_CRITERIA";
export const POST_API_CRITERIA = "POST_API_CRITERIA";
export const PUT_API_CRITERIA = "PUT_API_CRITERIA";
export const PUT_API_FITNEWSS_CRITERIA = "PUT_API_FITNEWSS_CRITERIA";
export const DELETE_API_FITNEWSS_CRITERIA = "DELETE_API_FITNEWSS_CRITERIA";
export const DELETE_API_CRITERIA = "DELETE_API_CRITERIA";

export const GET_API_INTERVIEW_SCRIPTS = "GET_API_INTERVIEW_SCRIPTS";
export const GET_API_INTERVIEW_SCRIPT_DESIGN = "GET_API_INTERVIEW_SCRIPT_DESIGN";
export const GET_API_INTERVIEW_SCRIPT_QUESTIONS = "GET_API_INTERVIEW_SCRIPT_QUESTIONS";
export const DELETE_API_INTERVIEW_SCRIPT_QUESTION = "DELETE_API_INTERVIEW_SCRIPT_QUESTION";
export const GET_API_INTERVIEW_SCRIPT_OPTIONS = "GET_API_INTERVIEW_SCRIPT_OPTIONS";
export const GET_API_INTERVIEW_SCRIPT_OPTION = "GET_API_INTERVIEW_SCRIPT_OPTION";
export const POST_API_INTERVIEW_SCRIPT_OPTION = "POST_API_INTERVIEW_SCRIPT_OPTION";
export const PUT_API_INTERVIEW_SCRIPT_OPTION = "PUT_API_INTERVIEW_SCRIPT_OPTION";
export const DELETE_API_INTERVIEW_SCRIPT_OPTION = "DELETE_API_INTERVIEW_SCRIPT_OPTION";
export const DELETE_API_INTERVIEW_SCRIPT = "DELETE_API_INTERVIEW_SCRIPT";
export const POST_API_INTERVIEW_SCRIPT = "POST_API_INTERVIEW_SCRIPT";
export const PUT_API_INTERVIEW_SCRIPT_DESIGN = "PUT_API_INTERVIEW_SCRIPT_DESIGN";
export const POST_API_INTERVIEW_SCRIPT_QUESTION = "POST_API_INTERVIEW_SCRIPT_QUESTION";
export const PUT_API_INTERVIEW_SCRIPT_QUESTION = "PUT_API_INTERVIEW_SCRIPT_QUESTION";

export const GET_API_INCENTIVE = "GET_API_INCENTIVE";
export const PUT_API_INCENTIVE = "PUT_API_INCENTIVE";
export const POST_API_INCENTIVE = "POST_API_INCENTIVE";
export const DELETE_API_INCENTIVE = "DELETE_API_INCENTIVE";
export const GET_API_INCENTIVE_LIST = "GET_API_INCENTIVE_LIST";

export const POST_API_INTERVIEW_SCRIPT_DESIGN = "POST_API_INTERVIEW_SCRIPT_DESIGN";

export const GET_API_TOKEN = "GET_API_TOKEN";