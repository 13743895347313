import React, {useEffect, useState} from "react";
import styled from "styled-components";

import radio_check from '../../../assets/images/radio_check.svg';
import trash from '../../../assets/images/trash-02.png';
import arrow_up from '../../../assets/images/arrow-up.png';
import arrow_down from '../../../assets/images/arrow-down.png';
import { deleteInterviewScriptQuestion, getInterviewScriptQuestions, postInterviewScriptQuestions, updateInterviewScriptQuestion } from "../../../action/api";
import { useDispatch, useSelector } from "react-redux";
import SaveToast from "../../../components/toast/save";
import { getErrorMessage } from "../../../config/common";

export default function Questions(props) {
    const { item, flag, flagFunc } = props;
    const [typeModal, setTypeModal] = useState(false);
    const [quizType, setQuizType] = useState("freeform");
    const [questions, setQuestions] = useState([]);
    const [newQuestion, setNewQuestion] = useState({"type":""});
    const [toast, setToast] = useState(-1);
    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {
        if (toast) {
          setTimeout(() => {
            setToast(-1)
          }, 5000);
        }
      }, [toast])
    const dispatch = useDispatch();
    useEffect(() => {
        getQuestions();
    }, [dispatch]);
    useEffect(() => {
        if (flag == 2) {
            if (newQuestion.type != '') {
                addQuestion({...newQuestion, order: questions.length + 1})
                setToast(1);
            } else {
                let flag = false;
                questions.forEach(question => {
                    const temp = { ...question };
                    delete temp['id'];
                    delete temp['created_at'];
                    delete temp['updated_at'];
                    delete temp['interview_script_id'];
                    temp['selections'].forEach(selection => {
                        delete selection['created_at'];
                        delete selection['updated_at'];
                    });

                    updateInterviewScriptQuestion(item.id, question.id, temp)(dispatch).then((res) => {
                        if (res.error !== undefined) {
                            setErrorMsg(getErrorMessage(res.fields));
                            setToast(2);
                            flag = true;
                            return;
                        }
                    });
                });
                if (flag === false)
                    setToast(1);
                // getQuestions();
            }
            flagFunc();
        }
    }, [flag])
    function getQuestions ()  {
        getInterviewScriptQuestions(item.id)(dispatch).then(res => {
            if (res.objects) setQuestions([...res.objects]);
            else setQuestions([]);
        });
    }
    const addQuestion = (obj) => {
        postInterviewScriptQuestions(item.id, obj)(dispatch).then((res) => {
            if (res.error !== undefined) {
                setToast(2);
                setErrorMsg(getErrorMessage(res.fields));
                return;
            }
            setNewQuestion({"type":""});
            getInterviewScriptQuestions(item.id)(dispatch).then(res => {
                setToast(1);
                if(res.objects) setQuestions([...res.objects]);
            }); 
        })
    }
    const changeQuestions = (type, index, newVal) => {
        const tempQuestions = [...questions];
        tempQuestions[index][type] = newVal;
        setQuestions([...tempQuestions]);
    }
    async function orderUpdate(quiz, qIndex, flag) {
        let tempFirObj = {
            "encouragement": questions[qIndex + flag].encouragement,
            "order": questions[qIndex + flag].order,
            "text": questions[qIndex + flag].text,
            "type": questions[qIndex + flag].type
        }
        let tempSecObj = {
            "encouragement": quiz.encouragement,
            "order": quiz.order,
            "text": quiz.text,
            "type": quiz.type
        }
        if(questions[qIndex + flag].type != 'freeform' && questions[qIndex + flag].selections)
            tempFirObj['selections'] = questions[qIndex + flag].selections
        if(quiz.type != 'freeform' && quiz.selections)
            tempSecObj['selections'] = quiz.selections;
        updateInterviewScriptQuestion(item.id, questions[qIndex + flag].id, tempSecObj)(dispatch).then((uRes) => {
            updateInterviewScriptQuestion(item.id, quiz.id, tempFirObj)(dispatch).then((res) => {
                setToast(1);
                getQuestions();
            });
        }).catch(err => {
            setToast(2);
        });
    }
    return (<QuestionsWrapper>
    <InterviewScriptHeader>
        <h2>
            Questions <br/>
            <span>Create your own interview questions...</span>
        </h2>
        <div className="question-btn" onClick={()=>{ setTypeModal(!typeModal)}}>
            <i className="fa fa-plus" aria-hidden="true"></i>
            Add Question
            {typeModal && (<div className="question-select card">
                <ul>
                    <li className={quizType == 'freeform' ? 'active': ''} onClick={()=>{
                        setQuizType('freeform');
                        if(newQuestion.type != '') {
                            addQuestion({...newQuestion, order: questions.length + 1})
                        }
                        setNewQuestion({
                            "encouragement": "moderate",
                            "order": 1,
                            "text":"",
                            "type":"freeform"   
                        })
                    }}>
                        Free-form text 
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M16.6673 5L7.50065 14.1667L3.33398 10" stroke="#1693C7" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </li>
                    <li className={quizType == 'pickone' ? 'active': ''} onClick={()=>{
                        setQuizType('pickone')
                        if(newQuestion.type != '') {
                            addQuestion({...newQuestion, order: questions.length + 1})
                        }
                        setNewQuestion({
                            "encouragement": "moderate",
                            "order": 1,
                            "selections": [
                                {"order": 1, "text": "" },
                                {"order": 2, "text": "" }
                            ],
                            "text":"",
                            "type":"pickone"   
                        })
                    }}>
                        Multiple Choice  (pick just one)
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M16.6673 5L7.50065 14.1667L3.33398 10" stroke="#1693C7" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </li>
                    <li className={quizType == 'pickmultiple' ? 'active': ''} onClick={()=>{
                        setQuizType('pickmultiple')
                        if(newQuestion.type != '') {
                            addQuestion({...newQuestion, order: questions.length + 1})
                        }
                        setNewQuestion({
                            "encouragement": "moderate",
                            "order": 1,
                            "selections": [
                                {"order": 1, "text": "" },
                                {"order": 2, "text": "" }
                            ],
                            "text":"",
                            "type":"pickmultiple"   
                        })
                    }}>
                        Multiple Choice (pick one or more)
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M16.6673 5L7.50065 14.1667L3.33398 10" stroke="#1693C7" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </li>
                </ul>
            </div>)}
        </div>
    </InterviewScriptHeader>
    <InterviewScriptBody>
        {questions && questions.length > 0 && questions.map((quiz, qIndex) => (
            <div className="script-section">
                <div className="section-header d-flex">
                    <h3>Question #{qIndex + 1}</h3>
                    <div className="section-right">
                        <select className="form-control  form-select" id="fitness-experience" aria-describedby="fitness-experience" value={quiz.type} onChange={(e) => {
                            changeQuestions('type', qIndex, e.target.value)
                        }}>
                            <option value="freeform">Free-form text</option>
                            <option value="pickone">Multiple choice (pick one or more)</option>
                            <option value="pickmultiple">Multiple Choice  (pick just one)</option>
                        </select>
                        <img src={trash} alt="" className="trash-img" onClick = {() => {
                            deleteInterviewScriptQuestion(item.id, quiz.id)(dispatch).then(res => {
                                console.log("dedddee", res)
                                getQuestions();
                            })
                        }}/>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor={"fitness-crierial"}>Write a question (required)</label>
                    <div className="form-input-group">
                        <input type="text" className="form-control" id="stand_call" aria-describedby="stand_call" placeholder="Tell us more about pandas and bamboo" value={quiz.text} onChange={(e) => {changeQuestions('text', qIndex, e.target.value)}}/>
                        {questions.length > 1 && (<div className="fa-icon">
                            {qIndex != 0 && (<img src={arrow_up} alt="" className="arrow-up-img" onClick={() => {
                                orderUpdate(quiz, qIndex, -1);
                            }}/>)}
                            {qIndex != questions.length - 1 && (<img src={arrow_down} alt="" className="arrow-up-img" onClick={() => {
                                orderUpdate(quiz, qIndex, 1);
                            }}/>)}
                        </div>)}
                    </div>
                </div>
                {quiz.type != 'freeform' && (<>
                    <hr />
                    {quiz.selections && quiz.selections.map((selection, sIndex) => (
                        <div className="form-group" key={'new-quiz-select-' + sIndex}>
                            <label htmlFor={"fitness-crierial"}>Answer {String.fromCharCode(65 + sIndex)}</label>
                            <div className="form-input-group">
                                <input type="text" className="form-control" id="stand_call" aria-describedby="stand_call" placeholder="" value={selection.text} onChange={(e) => {
                                    const tempArr = newQuestion.selections || quiz.selections;
                                    // delete tempArr['id'];

                                    tempArr[sIndex].text = e.target.value;
                                    changeQuestions('selections', qIndex, tempArr);
                                }}/>
                                <div className="fa-icon">
                                    <img src={arrow_down} alt="" className="arrow-down-img" />
                                </div>
                            </div>
                        </div>
                    ))}
                </>)}
                        <div className="form-group form-selects">
                            <label htmlFor={"fitness-crierial"}>Encouragement</label>
                            <select className="form-control form-select" id="fitness-experience" aria-describedby="fitness-experience" value={quiz.encouragement} onChange={(e) => {changeQuestions('encouragement', qIndex, e.target.value);}}>
                                <option value="low">Low</option>
                                <option value="moderate">Moderate</option>
                                <option value="high">High</option>
                            </select>
                        </div>
                    </div>
        ))}
        {newQuestion.type != '' && (
            <div className="script-section">
                <div className="section-header d-flex">
                    <h3>New Question</h3>
                    <div className="section-right">
                        <select className="form-control  form-select" id="fitness-experience" aria-describedby="fitness-experience" value={newQuestion.type} onChange={(e) => {
                            const selections = newQuestion.selections && newQuestion.selections.length > 0 ? [] : [
                                {"order": 1, "text": "" },
                                {"order": 2, "text": "" }
                            ];
                            let tempQuiz = {...newQuestion,type: e.target.value};
                            if(e.target.value != 'freeform')
                                tempQuiz = {...tempQuiz, selections: [...selections, ...newQuestion.selections]};
                            setNewQuestion({...tempQuiz})
                        }} >
                            <option value="freeform">Free-form text</option>
                            <option value="pickone">Multiple choice (pick one or more)</option>
                            <option value="pickmultiple">Multiple Choice  (pick just one)</option>
                        </select>
                        <img src={trash} alt="" className="trash-img" onClick={()=> {
                            setNewQuestion({"type":""});
                        }}/>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor={"fitness-crierial"}>Write a question (required)</label>
                    <div className="form-input-group">
                        <input type="text" className="form-control" id="stand_call" aria-describedby="stand_call" placeholder="Tell us more about pandas and bamboo" value={newQuestion.text} onChange={(e) => {setNewQuestion({...newQuestion,text: e.target.value})}}/>
                        <div className="fa-icon">
                            <img src={arrow_up} alt="" className="arrow-up-img" />
                        </div>
                    </div>
                </div>
                {newQuestion.type != 'freeform' && (<>
                    <hr />
                    {newQuestion.selections && newQuestion.selections.map((selection, sIndex) => (
                        <div className="form-group" key={'new-quiz-select-' + sIndex}>
                            <label htmlFor={"fitness-crierial"}>Answer {String.fromCharCode(65 + sIndex)}</label>
                            <div className="form-input-group">
                                <div className="form-input-text">
                                    <input type="text" className="form-control" id="stand_call" aria-describedby="stand_call" placeholder="" value={selection.text} onChange={(e) => {
                                        const tempArr = newQuestion.selections;
                                        tempArr[sIndex].text = e.target.value;
                                        setNewQuestion({...newQuestion, selections: [...tempArr]})
                                    }}/>
                                    <img src={trash} alt="" className="input-icon" onClick={()=> {
                                        const _selections = newQuestion.selections;
                                        _selections.splice(sIndex, 1);
                                        for (let i = sIndex; i < _selections.length; i++)
                                            _selections[i].order -= 1;
                                        console.log(newQuestion.selections, _selections);
                                        setNewQuestion({...newQuestion, selections: [..._selections]})
                                    }}/>
                                </div>
                                <div className="fa-icon">
                                    <img src={arrow_down} alt="" className="arrow-down-img" />
                                </div>
                            </div>
                        </div>
                    ))}
                    <br/>
                    <div
                        className="form-group">
                        <div
                        className="answer-btn"
                        onClick={() => {
                            setNewQuestion({...newQuestion, selections: [...newQuestion.selections, {order: newQuestion.selections.length + 1, text: ""}]});
                        }}
                        >
                            <i className="fa fa-plus" aria-hidden="true"></i>
                            New Answer
                        </div>
                    </div>
                </>)}
                {newQuestion.type == 'freeform' &&
                <div className="form-group form-selects">
                    <label htmlFor={"fitness-crierial"}>Encouragement</label>
                    <select className="form-control form-select" id="fitness-experience" aria-describedby="fitness-experience" value={newQuestion.encouragement} onChange={(e) => {setNewQuestion({...newQuestion, encouragement: e.target.value})}}>
                        <option value="low">Low</option>
                        <option value="moderate">Moderate</option>
                        <option value="high">High</option>
                    </select>
                </div>}
            </div>
        )}
        {newQuestion.type == '' && questions.length == 0 && ( 
            <p> There is no Question</p>
        )}
        {/* <div className="question-btn">
            <i className="fa fa-plus" aria-hidden="true"></i>
            Add Question
        </div> */}
    </InterviewScriptBody>
    {toast !== -1 && (<SaveToast closeToast={()=>{setToast(-1)}} toast={toast} error={errorMsg} />)}
  </QuestionsWrapper>)
}

const QuestionsWrapper = styled.div`
    position: relative;
    padding: 0px;
    width: 100%;
`
const InterviewScriptHeader = styled.div`
    padding: 20px 24px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    h2 {
        color: #101828;
        font-family: Figtree;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 155.556% */
        margin-bottom: 0px;
        span {
            color: #475467;
            font-family: Figtree;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
        }
    }
    .question-btn {
        padding: 10px 16px;
        height: 100%;
        border-radius: 8px;
        border: 1px solid #D0D5DD;
        background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: #344054;
        font-family: Figtree;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
        i {
            margin-right: 5px;
        }
    }
    .answer-btn {
        padding: 10px 16px;
        height: 100%;
        border-radius: 8px;
        border: 1px solid #D0D5DD;
        background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: #344054;
        font-family: Figtree;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
        i {
            margin-right: 5px;
        }
    }

    border-bottom: 1px solid #EAECF0;
    .question-btn {
        position: reltaive;
    }
    .question-select {
        position: absolute;
        right: 20px;
        top: 63px;
        border-radius: 8px;
        border: 1px solid var(--Gray-200, #EAECF0);
        background: var(--Base-White, #FFF);
        box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
        ul {
            list-style-type: none;
            padding: 6px;
            margin: 0px;
        }
        li {
            display: flex;
            padding: 10px 10px 10px 8px;
            flex-direction: row;
            align-items: flex-start;
            gap: 8px;
            flex: 1 0 0;
            width: 320px;
            justify-content: space-between;
            svg {
                display: none;
            }
            &.active{
                border-radius: 6px;
                background: var(--Gray-50, #F9FAFB);        
                svg{
                    display: block;
                }
            } 
            &:hover {
                border-radius: 6px;
                background: var(--Gray-50, #F9FAFB);
            }
        }
    }   
`
const InterviewScriptBody= styled.div`
    padding: 20px 24px;
    .script-section {
        border-radius: 8px;
        background: #F2F4F7;
        padding: 16px;
        h3 {
            color: #101828;
            font-family: Figtree;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
        }
        .section-header  {
            width: 100%;
            justify-content: space-between;
            .section-right {
                display: flex;
                i {
                    margin-left: 12px;
                    width: 20px;
                    line-height: 36px;
                }
            }
        }
        .fa-icon {
            display: flex;
            width: 60px;
            margin-left: 12px;
        }
        .form-group.form-selects {
            width: 200px;
        }
        .form-group .form-input-group {
            display: flex;
            i {
                cursor: pointer;
                color: #667085;
                width: 20px;
                line-height: 36px;
                margin-left: 6px;
            }
            .form-input-text {
                flex: 1;
                display: flex;
            }
        }
        .form-group label {
            font-family: Figtree;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: #344054;
        }
        .select-group {
            display: flex;
            p {
                margin-left: 10px;
                margin-right: 5px;
                min-width: 35px;
                margin-bottom: 0px;
                line-height: 38px;
            }
        }
        .form-check label {
            margin-bottom: 6px;
        }
        h4 {
            color: #101828;
            font-family: Figtree;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 142.857% */
            margin-top: 20px;
            margin-bottom: 12px;
        }
        .secondary.d-flex .form-group{
            margin-right: 20px;
        }
        margin-bottom: 16px;
        
    }
    .form-incentive {
        input.form-check-input {
            margin-top: 18px;
        }
        
        .form-check-label {
            display: flex;
            margin-bottom: 20px;
            .incentive-content{
                width: 100%;
                input {
                    border-radius: 8px;
                    border: 1px solid #D0D5DD);
                    background: #FFF;
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                    margin-top: 6px;
                }
            }
            .upload-img {
                h4 {
                    margin: 2px;
                    color: #475467;
                    font-family: Figtree;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px; /* 142.857% */
                    text-align: center;
                    margin-right: 16px;
                }
            }
            img {
                width: 52px;
                height: 52px;
                margin-right: 16px;
                margin-left: 8px;
            }
            h2 {
                color: #101828;
                font-family: Figtree;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px; /* 150% */
                margin-bottom: 0px;
            }
            p {
                color: #475467;
                font-family: Figtree;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 150% */     
                margin-bottom: 0px;
            }
        }
    }
    .question-btn {
        padding: 10px 16px;
        height: 100%;
        border-radius: 8px;
        border: 1px solid #D0D5DD;
        background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: #344054;
        font-family: Figtree;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
        i {
            margin-right: 5px;
        }
        display: block;
        margin-left: auto;
        width: 138px;
        position: relative;
    }
    .answer-btn {
        padding: 10px 16px;
        height: 100%;
        border-radius: 8px;
        border: 1px solid #D0D5DD;
        background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: #344054;
        font-family: Figtree;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
        i {
            margin-right: 5px;
        }
        display: block;
        width: 138px;
        position: relative;
    }
    select.form-control.form-select {
        border-radius: 8px;
        border: 1px solid var(--gray-300, #D0D5DD);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: var(--gray-500, #667085);
        font-family: Figtree;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
    }
    input[type=text].form-control {
        border-radius: 8px;
        border: 1px solid var(--gray-300, #D0D5DD);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: var(--gray-500, #667085);
        font-family: Figtree;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
    }
    .form-check-input:checked[type=radio] {
        border: 1px solid var(--primary-600, #1693C7);
        background: var(--primary-50, #E7F7FF);
        background-image: url(${radio_check});
        background-repeat: no-repeat;
        background-position: center;
        background-size: 6px 6 px;
    }
    .input-icon {
        margin-left: -30px;
        width: 20px;
        height: 20px;
        margin-top: auto;
        margin-bottom: auto;
        cursor: pointer;
    }
    .trash-img {
        width: 20px;
        height: 20px;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 12px;
        cursor: pointer;
    }
    .arrow-up-img, .arrow-down-img {
        margin-top: auto;
        margin-bottom: auto;
        width: 20px;
        height: 20px;
    }
`
