import {
  GET_API_ACCOUNTS,
  POST_API_ACCOUNT,
  PUT_API_ACCOUNT,
  GET_API_USERS,
  GET_API_USER,
  POST_API_USER,
  PUT_API_USER,
  DELETE_API_USER,
  GET_API_INTERVIEW_SESSIONS,
  GET_API_INTERVIEW_SESSION,
  DELETE_API_INTERVIEW_SESSION,
  GET_API_FITNEWSS_CRITERIA_LIST,
  GET_API_FITNEWSS_CRITERIA,
  POST_API_FITNEWSS_CRITERIA,
  PUT_API_FITNEWSS_CRITERIA,
  DELETE_API_FITNEWSS_CRITERIA,
  DELETE_API_CRITERIA,
  POST_API_CRITERIA,
  GET_API_INTERVIEW_SCRIPTS,
  GET_API_INTERVIEW_SCRIPT_DESIGN,
  GET_API_INTERVIEW_SCRIPT_QUESTIONS,
  DELETE_API_INTERVIEW_SCRIPT,
  POST_API_INTERVIEW_SCRIPT,
  PUT_API_INTERVIEW_SCRIPT_DESIGN,
  PUT_API_INTERVIEW_SCRIPT_QUESTION,
  PUT_API_CRITERIA,
  GET_API_INCENTIVE_LIST,
  PUT_API_INCENTIVE,
  POST_API_INTERVIEW_SCRIPT_DESIGN,
  POST_API_INTERVIEW_SCRIPT_QUESTION,
  GET_API_INTERVIEW_SCRIPT_OPTION,
  GET_API_INTERVIEW_SCRIPT_OPTIONS,
  POST_API_INTERVIEW_SCRIPT_OPTION,
  DELETE_API_INTERVIEW_SCRIPT_OPTION,
  PUT_API_INTERVIEW_SCRIPT_OPTION,
  DELETE_API_INTERVIEW_SCRIPT_QUESTION,
  POST_API_INCENTIVE,
  GET_API_TOKEN
} from "./type";

import ApiServices from "../services/apiServices";
 
export const getTokenData = ( email, password) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.getToken(email, password);
      dispatch({
        type: GET_API_TOKEN,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

// ===========  Accounts =============

export const receiveAccountsData = (pages = 1, rows = 10) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.getAccountsData(pages - 1, rows);
      dispatch({
        type: GET_API_ACCOUNTS,
        payload: res.data,
        pages: pages - 1,
        rows: rows
      });

      return res.data;
    } catch (err) {
      console.log("err", err);
    }
  };
};

export const getAccountData = (id) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.getAccount(id);
      dispatch({
        type: GET_API_USER,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response.data.error;   
    }
  };
};

export const addAccountData = (obj) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.postAccount(obj);
      console.log("add-res", res)
      dispatch({
        type: POST_API_ACCOUNT,
        payload: res,
      });
    } catch (err) {
      console.log("err", err);
    }
  };
}

export const updateAccountData = (id, obj) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.putAccount(id, obj);
      console.log('update-res', res)
      dispatch({
        type: PUT_API_ACCOUNT,
        payload: res,
      });
    } catch (err) {
      console.log("err", err);
    }
  }
}

// ===========  Users =============

export const getUsersData = (pages = 1, rows = 10, company = false) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.getUsers(pages - 1, rows, company);
      dispatch({
        type: GET_API_USERS,
        payload: res.data,
        pages: pages - 1
      });

      return res.data;
    } catch (err) {
      console.log("err", err);
    }
  };
};

export const getUserData = (id) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.getUser(id);
      dispatch({
        type: GET_API_USER,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response.data.error;   
    }
  };
};

export const addUserData = (obj) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.postUser(obj);
      dispatch({
        type: POST_API_USER,
        payload: res,
      });

      return res.data;
    } catch (err) {
      return err.response.data.error;   
     }
  };
}

export const updateUserData = (id, obj) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.putUser(id, obj);
      dispatch({
        type: PUT_API_USER,
        payload: res,
      });
    } catch (err) {
      return err.response.data.error;   
    }
  }
}

export const deleteUserData = (id) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.deleteUser(id);
      dispatch({
        type: DELETE_API_USER,
        payload: res,
      });
    } catch (err) {
      return err.response.data.error;   
    }
  }
}

// ===========  Interview Sessions =============

export const getInterviewSessionsData = (page = 1, rows = 10, company = false) => {
  return async (dispatch) => {
    try {
      const resParent = await ApiServices.getInterviewScripts(0, 999, company);
      let tempArr = [];
      if (resParent && resParent.data && resParent.data.objects){
        for (let i = 0; i< resParent.data.objects.length; i ++) {
          const res = await ApiServices.getInterviewSessions(resParent.data.objects[i].id, page - 1, rows);
          if (res.data && res.data.objects) {
            for (let j = 0; j < res.data.objects.length; j ++) {
              tempArr.push({...res.data.objects[j], parent_id: resParent.data.objects[i].id});
            }
          }
        }
        dispatch({
          type: GET_API_INTERVIEW_SESSIONS,
          payload: tempArr || []
        });

        return tempArr;
      }
      return tempArr;
    } catch (err) {
      console.log("err", err);
    }
  };
};

export const getInterviewSessionData = (id,id2) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.getInterviewSession(id, id2);
      dispatch({
        type: GET_API_INTERVIEW_SESSION,
        payload: res.data,
      });
      return res;
    } catch (err) {
      console.log("err", err);
    }
  };
};

export const deleteInterviewSessionData = (id) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.deleteInterviewSession(id);
      dispatch({
        type: DELETE_API_INTERVIEW_SESSION,
        payload: res,
      });
    } catch (err) {
      return err.response.data.error;   
    }
  }
}

// ===========  Fitness Criteria =============

export const getFitnessCriteriaListData = (pages = 1, rows = 10, company = false) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.getFitnessCriteriaList(pages - 1, rows, company);
      dispatch({
        type: GET_API_FITNEWSS_CRITERIA_LIST,
        payload: res.data,
        pages: pages - 1,
      });

      return res.data;
    } catch (err) {
      console.log("err", err);
    }
  };
};

export const getFitnessCriteriaData = (id) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.getFitnessCriteria(id);
      dispatch({
        type: GET_API_FITNEWSS_CRITERIA,
        payload: res.data,
      });
      return res;
    } catch (err) {
      return err.response.data.error;   
    }
  };
};

export const addFitnessCriteriaData = (obj) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.postFitnessCriteria(obj);
      dispatch({
        type: POST_API_FITNEWSS_CRITERIA,
        payload: res,
      });
    } catch (err) {
      return err.response.data;
    }
  };
}

export const updateFitnessCriteriaData = (id, obj) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.putFitnessCriteria(id, obj);
      dispatch({
        type: PUT_API_FITNEWSS_CRITERIA,
        payload: res,
      });

      return res;
    } catch (err) {
      return err.response.data.error;   
    }
  }
}

export const deleteFitnessCriteriaData = (id) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.deleteFitnessCriteria(id);
      dispatch({
        type: DELETE_API_FITNEWSS_CRITERIA,
        payload: res,
      });
      return res;
    } catch (err) {
      return err.response.data.error;   
    }
  }
}

export const deleteCriteriaData = (id, id2) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.deleteCriteria(id, id2);
      dispatch({
        type: DELETE_API_CRITERIA,
        payload: res,
      });
      return res;
    } catch (err) {
      console.log("err", err);
    }
  }
}

export const addCriteriaData = (id, obj) => {
  return async (dispatch) => {
    try {
      console.log("-addcriteria--'", id, obj)
      const res = await ApiServices.postCriteria(id, obj);
      dispatch({
        type: POST_API_CRITERIA,
        payload: res,
      });

      return res;
    } catch (err) {
      return err.response.data.error;
    }
  };
}

export const addIncentive = (obj) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.postIncentive(obj);
      dispatch({
        type: POST_API_INCENTIVE,
        payload: res,
      });

      return res;
    } catch (err) {
      return err.response.data.error;
    }
  };
}

export const updateIncentive = (id, obj) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.putIncentive(id, obj);
      dispatch({
        type: PUT_API_INCENTIVE,
        payload: res,
      });
      return res;
    } catch (err) {
      return err.response.data.error;
    }
  };
}

export const updateCriteriaData = (id, id2, obj) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.putCriteria(id, id2, obj);
      dispatch({
        type: PUT_API_CRITERIA,
        payload: res,
      });
      return res;
    } catch (err) {
      return err.response.data;   
    }
  }
}

// ===========  Interview Script =============

export const getInterviewScriptsData = (pages = 1, rows = 10, company = false) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.getInterviewScripts(pages - 1, rows, company);
      dispatch({
        type: GET_API_INTERVIEW_SCRIPTS,
        payload: res.data,
        pages: pages - 1
      });

      return res;
    } catch (err) {
      console.log("err", err);
    }
  };
};

export const getIncentiveList = (pages = 0) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.getIncentiveList(pages);
      dispatch({
        type: GET_API_INCENTIVE_LIST,
        payload: res.data,
      });
      return res.data;
    } catch (err) {
      console.log("err", err);
    }
  };
};

export const getInterviewScriptDesign = (id) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.getInterviewScriptDesign(id);
      dispatch({
        type: GET_API_INTERVIEW_SCRIPT_DESIGN,
        payload: res.data,
      });
      return res.data;
    } catch (err) {
      console.log("err", err);
    }
  };
};

export const getInterviewScriptQuestions = (id) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.getInterviewScriptQuestions(id);
      dispatch({
        type: GET_API_INTERVIEW_SCRIPT_QUESTIONS,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      console.log("err", err);
    }
  };
};

export const deleteInterviewScriptQuestion = (id, id2) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.deleteInterviewScriptQuestion(id, id2);
      dispatch({
        type: DELETE_API_INTERVIEW_SCRIPT_QUESTION,
        payload: res,
      });
    } catch (err) {
      return err.response.data.error;   
    }
  }
}

export const getInterviewScriptOptions = (id) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.getInterviewScriptOptions(id);
      dispatch({
        type: GET_API_INTERVIEW_SCRIPT_OPTIONS,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      console.log("err", err);
    }
  };
};

export const getInterviewScriptOption = (id, id2) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.getInterviewScriptOption(id, id2);
      dispatch({
        type: GET_API_INTERVIEW_SCRIPT_OPTION,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      console.log("err", err);
    }
  };
};

export const addInterviewScriptOption = (id, obj) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.postInterviewScriptOption(id, obj);
      dispatch({
        type: POST_API_INTERVIEW_SCRIPT,
        payload: res,
      });
    } catch (err) {
      return err.response.data.error;   
    }
  };
}

export const addInterviewScriptData = (obj) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.postInterviewScripts(obj);
      dispatch({
        type: POST_API_INTERVIEW_SCRIPT,
        payload: res,
      });
    } catch (err) {
      return err.response.data.error;   
    }
  };
}

export const postInterviewScriptDesign = (id, obj) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.postInterviewScriptDesign(id, obj);
      dispatch({
        type: POST_API_INTERVIEW_SCRIPT_DESIGN,
        payload: res,
      });

      return res;
    } catch (err) {
      return err.response.data;   
    }
  }
}

export const updateInterviewScriptDesign = (id, id2, obj) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.putInterviewScriptDesign(id, id2, obj);
      dispatch({
        type: PUT_API_INTERVIEW_SCRIPT_DESIGN,
        payload: res,
      });

      return res;
    } catch (err) {
      return err.response.data;   
    }
  }
}

export const postInterviewScriptQuestions = (id, obj) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.postInterviewScriptQuestions(id, obj);
      dispatch({
        type: POST_API_INTERVIEW_SCRIPT_QUESTION,
        payload: res,
      });

      return res; 
    } catch (err) {
      return err.response.data;
    }
  }
}

export const updateInterviewScriptQuestion = (id, id2, obj) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.putInterviewScriptQuestions(id, id2, obj);
      dispatch({
        type: PUT_API_INTERVIEW_SCRIPT_QUESTION,
        payload: res,
      });

      return res;
    } catch (err) {
      return err.response.data;
    }
  }
}

export const updateInterviewScriptOptions = (id,id2, obj) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.putInterviewScriptOptions(id, id2, obj);
      dispatch({
        type: PUT_API_INTERVIEW_SCRIPT_OPTION,
        payload: res,
      });
    } catch (err) {
      console.log("err", err);
    }
  }
}

export const updateInterviewScriptQuestions = (id, obj) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.putInterviewScriptQuestions(id, obj);
      dispatch({
        type: PUT_API_INTERVIEW_SCRIPT_QUESTION,
        payload: res,
      });
    } catch (err) {
      return err.response.data;   
    }
  }
}

export const deleteInterviewScriptsData = (id) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.deleteInterviewScripts(id);
      dispatch({
        type: DELETE_API_INTERVIEW_SCRIPT,
        payload: res,
      });
      return res;
    } catch (err) {
      return err.response.data.error;   
    }
  }
}
